import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  makeStyles,
} from "@material-ui/core";
import Vimeo from "@u-wave/react-vimeo";

import Footer from "../../components/Footer";
import SimpleHeader from "../../components/SimpleHeader";
import AuthContext from "../../contexts/AuthContext";
import ClientContext from "../../contexts/ClientContext";
import { useParams } from "react-router-dom";

// import enviroment from "../../config/enviroment";

export default function VideosStage() {
  const { stageId } = useParams();

  const { apiRequest } = React.useContext(ClientContext);
  const { currentUser } = React.useContext(AuthContext);
  const [videoStage, setVideoStage] = React.useState(null);
  const [loadingStages, setLoadingVideo] = React.useState(true);
  const [playVideo, setPlayVideo] = React.useState(null);
  const [video, setVideo] = React.useState(null);

  React.useEffect(() => {
    // setErrorLoading(null)
    setLoadingVideo(true);

    apiRequest("GET", `/vip_area/stages/${stageId}/videos`)
      .then((response) => {
        // const eventTickets = response.data.filter(ticket => ticket.event_id === enviroment.event_id)
        // console.log('VIDEO RESPONSE ', response.data)
        setVideoStage(response.data);
        setLoadingVideo(false);
      })
      .catch((err) => {
        console.log("ERR ", err);
        setLoadingVideo(false);
        // setErrorLoading(err.message)
      });
  }, []);

  React.useEffect(() => {
    if (!currentUser || !currentUser?.user?.is_vip)
      window.location.href = "/evento";
  }, [currentUser]);

  return (
    <>
      <div style={{ background: "#fff" }}>
        <SimpleHeader />
        <div
          style={{
            backgroundColor: "#fff",
            padding: "10px 0",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <h4>Workshop</h4>
          <Button
            onClick={() => window.location.replace("/palcos")}
            style={{ maxWidth: 100 }}
          >
            Palcos
          </Button>
        </div>

        {loadingStages && (
          <div style={{ alignItems: "center", background: "#fff" }}>
            <CircularProgress size={26} />
          </div>
        )}

        {videoStage && videoStage.length === 0 && (
          <div
            style={{
              height: "50vh",
              alignItems: "center",
              background: "#fff",
              padding: "30px 0",
            }}
          >
            Sem conteúdo disponível.
          </div>
        )}

        <div
          style={{
            minHeight: 450,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {playVideo && (
            <>
              {video && video.provider === "youtube" ? (
                <iframe
                  title="video"
                  width="560"
                  height="315"
                  src={video?.url}
                  style={{ width: "100%", maxWidth: 560 }}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
              ) : video.provider === "vimeo" ? (
                <Vimeo
                  video={video?.url}
                  autoplay
                  controls={true}
                  style={{ height: "100%", width: "100%", marginTop: 12 }}
                />
              ) : (
                <div
                  style={{
                    margin: "auto",
                    border: "1px solid #3D8ED8",
                    maxWidth: 350,
                    borderRadius: 10,
                  }}
                >
                  <a
                    href={video.url}
                    target="blank"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src="/images/zoom-image.png"
                      alt="zoom"
                      style={{ width: "90%" }}
                    />
                    <p style={{ color: "#3D8ED8", textDecoration: "none" }}>
                      Você será redirecionado para um link externo
                    </p>
                  </a>
                </div>
              )}

              <Button
                // variant="outlined"
                style={{ maxWidth: 100, marginTop: 20 }}
                onClick={() => {
                  setPlayVideo(null);
                  setVideo(null);
                }}
                // classes={{ root: classes.outlined, label: classes.label, }}
              >
                Voltar
              </Button>
            </>
          )}

          {!playVideo && (
            <Grid container justify="center" style={{ marginTop: 20 }}>
              {videoStage &&
                videoStage.map((video, index) => (
                  <Card
                    key={String(index)}
                    style={{
                      margin: 10,
                      maxWidth: 300,
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (video.url?.includes("zoom")) {
                        setPlayVideo("zoom");
                      } else {
                        setPlayVideo(video.provider_id);
                      }
                      setVideo(video);
                    }}
                  >
                    <CardMedia
                      style={{ height: 120 }}
                      image={video.thumbnail_url}
                      title={video.speaker_name}
                    />

                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1.1rem",
                            lineHeight: 1.334,
                            letterSpacing: "0em",
                            fontWeight: "bold",
                          }}
                        >
                          {video.title}
                        </span>
                        <span
                          style={{
                            fontSize: "0.8rem",
                            lineHeight: 1.5,
                            letterSpacing: "0.009em",
                            color: "#bbb",
                          }}
                        >
                          {video.speaker_name}
                        </span>
                      </div>
                    </CardContent>
                  </Card>
                ))}
            </Grid>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

const useStyles = makeStyles({
  root: {
    background: "#3D8ED8",
    borderRadius: 3,
    border: 0,
    width: "100%",
    color: "white",
    height: 42,
    padding: "0 40px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#3D8ED8",
    },
  },
  outlined: {
    background: "transparent",
    width: "100%",
    maxWidth: 280,
    borderRadius: 3,
    border: "1px solid #3D8ED8",
    color: "#3D8ED8",
    height: 42,
    marginTop: 20,
    marginBottom: 20,
    padding: "0 40px",
    "&:hover": {
      background: "#3D8ED8",
      color: "white",
      boxShadow: "0 3px 5px 2px rgba(0, 0, 120, .3)",
    },
  },
  label: {
    textTransform: "capitalize",
  },
});
