import styled from "styled-components";
import colors from "../../theme/colors";

export const Container = styled.div`
  height: 100vh;
  background: ${colors.background};
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 40px;
  }
`;

export const ContainerButtonSendLink = styled.div`
  margin-top: 30px;
`;

export const ContainerRegisterAccount = styled.div`
  margin-top: 40px;
  color: ${colors.text};

  a {
    color: ${colors.text};
  }
`;
