import React from "react";

import { Card, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { Link } from "react-router-dom";

import Button from "../../components/Button";
import InputField from "../../components/InputField";
import SimpleHeader from "../../components/SimpleHeader";

import ClientContext from "../../contexts/ClientContext";

import {
  Container,
  ContainerButtonSendLink,
  ContainerRegisterAccount,
  Content,
} from "./styles";
import colors from "../../theme/colors";

export default function ForgotPassword() {
  const [email, setEmail] = React.useState("");

  const [loadingReset, setLoadingReset] = React.useState(null);

  const [successReset, setSuccessReset] = React.useState(null);
  const [errorReset, setErrorReset] = React.useState(null);

  const { apiRequest } = React.useContext(ClientContext);

  const submitResetPassword = (e) => {
    console.log("reset");
    e.preventDefault();
    setLoadingReset(true);
    setErrorReset(null);

    apiRequest("POST", "/password/reset", { email })
      .then((res) => {
        setSuccessReset(true);
        setLoadingReset(false);
      })
      .catch((err) => {
        setLoadingReset(false);
        setErrorReset("Erro ao enviar solicitação!");
      });
  };

  if (successReset)
    return (
      <div>
        <SimpleHeader />

        <Container>
          <Content>
            <Card
              style={{
                padding: 20,
                background: "transparent",
                textAlign: "center",
              }}
            >
              <CheckCircle style={{ color: "#66bb6a", fontSize: 80 }} />
              <Typography
                variant="h4"
                style={{
                  textAlign: "center",
                  fontWeight: 300,
                  margin: 0,
                  marginTop: 12,
                  color: colors.text,
                }}
              >
                Confira seu e-mail, te enviamos todas as instruções!
              </Typography>
              <Button
                style={{ maxWidth: 180, marginTop: 20 }}
                onClick={() => {
                  window.location.replace("/");
                }}
              >
                Voltar
              </Button>
            </Card>
          </Content>
        </Container>
      </div>
    );

  return (
    <Container className="background">
      <Content className="content-home">
        <img src="/images/arena_xperience_white.png" width="200" alt="logo" />

        <form onSubmit={submitResetPassword}>
          <InputField
            label="Seu e-mail"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {errorReset && (
            <p style={{ color: "red", fontSize: 13 }}>{errorReset}</p>
          )}

          <ContainerButtonSendLink>
            <Button disabled={loadingReset}>Enviar link</Button>
          </ContainerButtonSendLink>
          <ContainerRegisterAccount>
            Ainda não tem uma conta?{" "}
            <Link to="/register">Criar nova conta</Link>
          </ContainerRegisterAccount>
        </form>
      </Content>
    </Container>
  );
}
