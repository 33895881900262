import styled from "styled-components";
import colors from "../../theme/colors";

export const Container = styled.div`
  height: 100vh;
  background: ${colors.background};
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 350px;
`;
