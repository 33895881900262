import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import PersonIcon from "@material-ui/icons/Person";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";

import AuthContext from "../../contexts/AuthContext";
import ClientContext from "../../contexts/ClientContext";

import colors from "../../theme/colors";
// import enviroment from "../config/enviroment";
import { HeaderContainer } from "./styles";

const StyledMenu = withStyles({
  paper: {
    // border: "1px solid #d3d4d5",
    background: colors.background,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      // background: colors.background,
      // color: colors.text,
      // background: `linear-gradient(90deg, ${colors.secundary} 0%, ${colors.tertiary} 100%)`,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        // colors: theme.palette.common.white,
        // background: colors.text,
        // color: colors.text,
      },
    },
  },
}))(MenuItem);

export default function SimpleHeader() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { currentUser, signOut } = React.useContext(AuthContext);
  const { apiRequest } = React.useContext(ClientContext);

  const submitLogout = (e) => {
    e.preventDefault();
    apiRequest("GET", "/logout")
      .then((res) => {
        signOut();
      })
      .catch((err) => {
        console.log(err);
        signOut();
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderContainer>
      <div>
        <img
          onClick={() => (window.location.href = "/evento")}
          src="/images/arena-xp-logo-branca.png"
          height="40"
          style={{ cursor: "pointer" }}
          alt="logo"
        />
      </div>

      {currentUser ? (
        <div>
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={handleClick}
            style={{
              background: "transparent",
              boxShadow: "none",
              border: "1px solid #fff",
            }}
          >
            <AccountCircleIcon fontSize="small" />
          </Button>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem
              onClick={() => (window.location.href = "/meus-dados")}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" htmlColor={colors.white} />
              </ListItemIcon>
              <ListItemText primary="Minha conta" htmlColor={colors.white} />
            </StyledMenuItem>

            {/* <StyledMenuItem
              onClick={() => (window.location.href = "/evento/programacao")}
            >
              <ListItemIcon>
                <CalendarTodayIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Programação" />
            </StyledMenuItem> */}

            {currentUser?.user?.is_vip && (
              <StyledMenuItem
                onClick={() => (window.location.href = "/palcos")}
              >
                <ListItemIcon>
                  <ImportantDevicesIcon
                    fontSize="small"
                    htmlColor={colors.white}
                  />
                </ListItemIcon>
                <ListItemText primary="Ao vivo" />
              </StyledMenuItem>
            )}

            <StyledMenuItem
              onClick={() => (window.location.href = "/meus-ingressos")}
            >
              <ListItemIcon>
                <ConfirmationNumberIcon
                  fontSize="small"
                  htmlColor={colors.white}
                />
              </ListItemIcon>
              <ListItemText primary="Minhas inscrições" />
            </StyledMenuItem>

            <StyledMenuItem onClick={submitLogout}>
              <ListItemIcon>
                <PowerSettingsNewIcon
                  fontSize="small"
                  htmlColor={colors.white}
                />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </StyledMenuItem>
          </StyledMenu>
        </div>
      ) : (
        <Button
          style={{ color: "#fff", maxWidth: 150 }}
          onClick={() => (window.location.href = "/login")}
          variant="outlined"
        >
          Entrar
        </Button>
      )}
    </HeaderContainer>
  );
}
