import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import colors from "./colors";

export default function ThemeConfig({ children }) {
  const themeApplication = createMuiTheme({
    palette: {
      primary: {
        main: colors.white,
      },
    },
    typography: {
      fontFamily: "Montserrat",
      allVariants: {
        color: colors.text,
      },
    },
    overrides: {
      MuiFormLabel: {
        root: {
          color: colors.text,
        },
      },
      MuiInputBase: {
        root: {
          color: colors.text,
        },
      },
      MuiOutlinedInput: {
        input: {
          "&::placeholder": {
            color: colors.placeholder,
          },
          color: colors.text,
        },
        root: {
          "&::placeholder": {
            color: colors.placeholder,
          },
          color: colors.text,
        },
      },
      MuiInputLabel: {
        root: {
          color: colors.text,
        },
      },
      MuiInput: {
        input: {
          "&::placeholder": {
            color: colors.placeholder,
          },

          color: colors.text,
        },
        underline: {
          "&:before": {
            borderBottom: `1px solid ${colors.placeholder}`,
          },
          "&:after": {
            borderBottom: `2px solid ${colors.text}`,
          },
          "&:hover:before": {
            borderBottom: `1px solid ${colors.text}`,
          },
          "&:hover:after": {
            borderBottom: `2px solid ${colors.text}`,
          },
          "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: `1px solid ${colors.text}`,
          },
        },
        root: {
          "&::placeholder": {
            color: colors.placeholder,
          },
          color: colors.text,
        },
      },
      MuiTextField: {
        input: {
          "&::placeholder": {
            color: colors.placeholder,
          },
          color: colors.text,
        },
        root: {
          "&::placeholder": {
            color: colors.placeholder,
          },
          color: colors.text,
        },
      },
      MuiButton: {
        root: {
          height: 42,
          // background: colors.gradient,
          width: "100%",
          // maxWidth: 280,
          borderRadius: 4,
          border: 0,
          color: "white",
          padding: "0 40px",
          boxShadow: `0 3px 4px 0px ${colors.primary}30`,
          "&:hover": {
            // background: `linear-gradient(90deg, ${colors.tertiary} 0%, ${colors.secundary} 100%)`,
          },
          "&:disabled": {
            background: "#ddd",
            // boxShadow: "0 3px 5px 2px rgba(255, 255, 255, .3)",
            cursor: "not-allowed",
          },
        },
        outlined: {
          background: "transparent",
        },
        label: {
          // textTransform: "capitalize",
          color: colors.text,
          fontWeight: "bold",
        },
      },
      MuiFormControlLabel: {
        root: {
          color: colors.text,
        },
      },
      MuiCheckbox: {
        root: {
          color: colors.text,
        },
      },
      MuiCard: {
        root: {
          backgroundColor: colors.darkLight,
          color: colors.text,
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: colors.darkLight,
          color: colors.text,
        },
      },
    },
  });

  return <ThemeProvider theme={themeApplication}>{children}</ThemeProvider>;
}
