import axios from 'axios'

export const serverRequest = ({method, url, params, user}) => {
  return new Promise((resolve, reject) => {
    try{
      method = (method || "get").toLowerCase()
      let requestInfo = {
        method: method,
        url: url,
        baseURL: process.env.REACT_APP_SERVER_URL,
        headers: {
          "Content-Type": 'application/json',
          UserId: user && user.id,
          Authorization: "Bearer " + (user && user.token)
        }
      }

      // console.log('TOKEN ', user)

      if(method === "get") requestInfo.params = params
      else requestInfo.data = params

      // console.log(requestInfo)

      axios(requestInfo)
      .then(res => {
        if(res.data.error) reject(new Error(res.data.error))
        else resolve(res.data)
      })
      .catch(err => {
        try{
          reject(new Error(err.response.data.error))
        }catch(ignored){
          reject(new Error(`Falha interna - serviço indisponível`))
        }
      })
    }catch(err){
      reject(err)
    }
  })
}
