import React from "react";

import { Card, CircularProgress, Container } from "@material-ui/core";
import { CheckCircle, Clear } from "@material-ui/icons";

import SimpleHeader from "../components/SimpleHeader";
import { useParams } from "react-router-dom";
import ClientContext from "../contexts/ClientContext";

export default function VerifyOrder() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(null);

  const { orderId } = useParams();
  const { apiRequest } = React.useContext(ClientContext);

  React.useEffect(() => {
    setIsLoading(true);
    verifyOrder();
  }, []);

  async function verifyOrder() {
    try {
      apiRequest("POST", "/capture", { orderId })
        .then((data) => {
          data?.status ? setIsSuccess(true) : setIsSuccess(false);

          setIsLoading(false);
        })
        .catch((err) => {
          setIsSuccess(false);
          setIsLoading(false);
        });
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
    }
  }

  return (
    <div>
      <SimpleHeader />

      <Container>
        <Card style={{ padding: 30, marginTop: 20 }}>
          {isLoading ? (
            <CircularProgress size={22} />
          ) : (
            <>
              <div>
                {isSuccess ? (
                  <CheckCircle style={{ color: "#66bb6a", fontSize: 80 }} />
                ) : (
                  <Clear style={{ color: "rgb(194, 39, 38)", fontSize: 80 }} />
                )}

                <h1
                  style={{
                    textAlign: "center",
                    fontSize: 48,
                    fontWeight: 300,
                    margin: 0,
                  }}
                >
                  {isSuccess ? "Compra finalizada!" : "Ocorreu um erro!"}
                </h1>
              </div>

              <div style={{ marginTop: 50 }}>
                <h2 style={{ textAlign: "center", margin: 0, marginBottom: 6 }}>
                  {isSuccess ? "Parabéns" : "Ocorreu um problema"}
                </h2>
                <h3
                  style={{
                    textAlign: "center",
                    color: "#666",
                    fontWeight: 300,
                    margin: 0,
                  }}
                >
                  {isSuccess
                    ? "Você completou sua compra com sucesso!"
                    : "Sua compra não foi processada, tente novamente."}
                </h3>
              </div>
            </>
          )}
        </Card>
      </Container>
    </div>
  );
}
