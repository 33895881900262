import styled from "styled-components";
import colors from "../../theme/colors";

export const HeaderContainer = styled.header`
  background: black;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
