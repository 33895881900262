import styled from "styled-components";

export const ContainerProfile = styled.div`
  .card-profile {
    background-color: #333;
    .input-field {
      background-color: #333;
      width: 100%;
    }
    li {
      background-color: #333;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
