import React from "react";
import {
  CircularProgress,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  // Button,
  ListItem,
  ListItemText,
  List,
  DialogTitle,
  IconButton,
  Card,
  Container,
  Snackbar,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@material-ui/core";
import { CheckCircle, Clear } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import PixelFB from "react-facebook-pixel";
import QRCode from "react-qr-code";

import ButtonComponent from "../../components/Button";
import InputField from "../../components/InputField";
import Footer from "../../components/Footer";
import SimpleHeader from "../../components/SimpleHeader";
import AuthContext from "../../contexts/AuthContext";
import ClientContext from "../../contexts/ClientContext";
import { formatReais } from "../../util/Util";
// import SignUp from './SignUp';

// import "./Event.scss";
import enviroment from "../../config/enviroment";
import colors from "../../theme/colors";
import { ContainerEvent } from "./styles";
import Button from "../../components/Button";

export default function Event() {
  const [event, setEvent] = React.useState(null);
  const [loadingEvent, setLoadingEvent] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState(null);

  const [selectedTickets, setSelectedTickets] = React.useState([]);

  const [buyingModal, setBuyingModal] = React.useState(null);
  const [loadingBuy, setLoadingBuy] = React.useState(false);
  const [errorBuy, setErrorBuy] = React.useState(null);

  const [copiedBarcode, setCopiedBarcode] = React.useState(false);

  const [successPayment, setSuccessPayment] = React.useState(null);

  const { apiRequest } = React.useContext(ClientContext);
  const { currentUser } = React.useContext(AuthContext);

  PixelFB.init("1662363077256342");

  const classes = useStyles();

  const makePayment = (order) => {
    const url =
      buyingModal.payment_way === "credit"
        ? "/order/charge/credit"
        : "/pix/charge";

    let params = {
      order_id: order.id,
    };
    if (buyingModal.payment_way === "credit") {
      Object.assign(params, {
        card_expiration_date: { ...buyingModal.card }.expiry,
        card_holder: { ...buyingModal.card }.holder,
        cvv: { ...buyingModal.card }.cvv,
        card_number: { ...buyingModal.card }.number.replace(/ /g, ""),
        installments: { ...buyingModal.card }.installments || 1,
      });
    } else if (buyingModal.payment_way === "pix") {
      params = {
        orderId: order.id,
        userId: currentUser?.user?.id,
      };
    }

    apiRequest("POST", url, params)
      .then((res) => {
        setLoadingBuy(false);
        console.log("res ", res);

        setSuccessPayment({ paymentWay: buyingModal.payment_way, res });
      })
      .catch((err) => {
        setLoadingBuy(false);
        setErrorBuy(err.message);
      });
  };

  const submitBuy = (e) => {
    e.preventDefault();

    try {
      setLoadingBuy(true);
      setErrorBuy(null);

      const params = {
        totalPrice: selectedTickets.reduce(
          (pv, item) => pv + item.quantity * item.ticket.price,
          0
        ),
        tickets: JSON.stringify(
          selectedTickets.map((st) => ({
            id: st.ticket.id,
            quantity_requested: parseInt(st.quantity),
            event_id: st.ticket?.type_of_ticket_id,
            type_of_ticket_id: st.ticket?.type_of_ticket_id,
          }))
        ),
      };
      if (!["credit", "pix"].includes(buyingModal.payment_way))
        throw new Error("Selecione uma forma de pagamento");
      if (["credit"].includes(buyingModal.payment_way)) {
        if (!buyingModal.card) throw new Error("Informe os dados do cartão");
        if (!buyingModal.card.number)
          throw new Error("Informe número do cartão");
        if (!buyingModal.card.holder)
          throw new Error("Informe nome do proprietário como está no cartão");
        if (!buyingModal.card.expiry)
          throw new Error("Informe a data de vencimento do cartão");
        if (!buyingModal.card.cvv)
          throw new Error("Informe o número de segurança do cartão");
      }

      apiRequest("POST", "/order/create", params)
        .then(({ data }) => {
          //     console.log('data order', data)
          makePayment(data);
        })
        .catch((err) => {
          setLoadingBuy(false);
          err.message
            ? setErrorBuy(err.message)
            : setErrorBuy("Faça o login para comprar esse item.");
        });
    } catch (err) {
      setLoadingBuy(false);
      err.message
        ? setErrorBuy(err.message)
        : setErrorBuy("Faça o login para comprar esse item.");
    }
  };

  const startAgain = () => {
    setSuccessPayment(null);
    setBuyingModal(null);
    setSelectedTickets([]);
  };

  React.useEffect(() => {
    setErrorLoading(null);
    setLoadingEvent(true);

    apiRequest("GET", `/events/${enviroment.event_id}`)
      .then(({ event }) => {
        if (!event[0]) throw new Error("Evento não encontrado");

        setTimeout(() => {
          setLoadingEvent(false);
          setEvent(event[0]);
        }, 1000);
      })
      .catch((err) => {
        setLoadingEvent(false);
        setErrorLoading(err.message);
      });
  }, []);

  // const quantityTotalTickets = React.useMemo(() => {
  //   console.log("quantitu");
  //   return selectedTickets.reduce((pv, item) => pv + item.quantity, 0);
  // }, [selectedTickets]);

  const openPdf = (url) => {
    window.open(url, "_blank");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setCopiedBarcode(false);
  };

  if (successPayment && successPayment.paymentWay === "boleto")
    return (
      <div style={{ textAlign: "center" }}>
        <SimpleHeader />

        <Container>
          <Card style={{ padding: 30, marginTop: 20 }}>
            <CheckCircle style={{ color: "#66bb6a", fontSize: 60 }} />
            <h1 style={{ textAlign: "center" }}>Boleto gerado com sucesso!</h1>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <p
                id="barcode"
                style={{
                  textAlign: "center",
                  color: "#bbb",
                  wordBreak: "break-all",
                }}
              >
                {successPayment?.res?.data?.barcodeNumber}{" "}
              </p>
            </div>

            <div>
              <Button
                variant="outlined"
                style={{ marginLeft: 6, marginTop: 10 }}
                onClick={() => {
                  setCopiedBarcode(true);
                  navigator.clipboard.writeText(
                    successPayment?.res.data.barcodeNumber
                  );
                }}
              >
                Copiar boleto
              </Button>
              <Button
                variant="outlined"
                style={{ marginLeft: 6, marginTop: 10 }}
                onClick={() => openPdf(successPayment?.res?.data?.url)}
              >
                Abrir boleto
              </Button>
            </div>

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={copiedBarcode}
              autoHideDuration={3000}
              onClose={handleClose}
              message="Copiado!"
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />

            <h3 style={{ textAlign: "center" }}>
              Confira seu e-mail para mais detalhes e informações do pagamento.
            </h3>
          </Card>
        </Container>

        <div style={{ padding: 20, textAlign: "center" }}>
          <Button variant="outlined" onClick={startAgain}>
            Comprar novamente
          </Button>
        </div>
      </div>
    );

  if (successPayment && successPayment.paymentWay === "pix")
    return (
      <div style={{ textAlign: "center" }}>
        <SimpleHeader />

        <Container>
          <Card style={{ padding: 30, marginTop: 20 }}>
            <CheckCircle style={{ color: "#66bb6a", fontSize: 60 }} />
            <h1 style={{ textAlign: "center" }}>Pix gerado com sucesso!</h1>
            <p
              style={{
                textAlign: "center",
                color: "#bbb",
                wordBreak: "break-all",
              }}
            >
              ATENÇÃO: Utilize o código copiado na opção Pix Copia e Cola no
              aplicativo do seu banco!
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <QRCode value={successPayment?.res?.data?.qr_code} />
            </div>

            <div style={{ maxWidth: 256, margin: "auto", marginTop: 20 }}>
              <ButtonComponent
                type="button"
                style={{ background: colors.background }}
                onClick={() => {
                  setCopiedBarcode(true);
                  navigator.clipboard.writeText(
                    successPayment?.res?.data?.qr_code
                  );
                }}
              >
                Copiar pix
              </ButtonComponent>
            </div>

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={copiedBarcode}
              autoHideDuration={3000}
              onClose={handleClose}
              message="Copiado!"
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          </Card>
        </Container>

        <div
          style={{
            padding: 20,
            textAlign: "center",
            maxWidth: 350,
            margin: "auto",
          }}
        >
          <ButtonComponent
            style={{ background: colors.background }}
            type="button"
            onClick={startAgain}
          >
            Comprar novamente
          </ButtonComponent>
        </div>
      </div>
    );

  if (successPayment && successPayment.paymentWay == "credit")
    return (
      <div>
        <SimpleHeader />

        <Container>
          <Card
            style={{
              padding: 30,
              maxWidth: 400,
              margin: "auto",
              marginTop: 20,
              textAlign: "center",
            }}
          >
            {successPayment?.res?.status ? (
              <CheckCircle style={{ color: "#66bb6a", fontSize: 80 }} />
            ) : (
              <Clear style={{ color: "rgb(194, 39, 38)", fontSize: 80 }} />
            )}

            <h1
              style={{
                textAlign: "center",
                fontSize: 48,
                fontWeight: 300,
                margin: 0,
              }}
            >
              {successPayment?.res?.status
                ? "Compra finalizada!"
                : "Ocorreu um erro!"}
            </h1>

            <div style={{ marginTop: 50 }}>
              <h2 style={{ textAlign: "center", margin: 0, marginBottom: 6 }}>
                {successPayment?.res?.status
                  ? "Parabéns"
                  : "Ocorreu um problema"}
              </h2>
              <h3
                style={{
                  textAlign: "center",
                  color: "#666",
                  fontWeight: 300,
                  margin: 0,
                }}
              >
                {successPayment?.res?.status
                  ? "Você completou sua compra com sucesso!"
                  : "Sua compra não foi processada, tente novamente."}
              </h3>
            </div>
          </Card>
        </Container>
        <div
          style={{
            padding: 20,
            textAlign: "center",
            maxWidth: 360,
            margin: "auto",
          }}
        >
          <ButtonComponent
            style={{ background: colors.background }}
            type="button"
            onClick={startAgain}
          >
            Comprar novamente
          </ButtonComponent>
        </div>
      </div>
    );

  return (
    <ContainerEvent>
      <SimpleHeader />
      {/* <header style={{ margin: "auto" }}>
        <img
          src="/images/logo_arena23.png"
          alt="publico_arena_conference_blur"
          height="120"
          style={{ marginTop: 20 }}
        />
      </header> */}

      {loadingEvent ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // background: "#fff",
            minHeight: 414,
            justifyContent: "center",
          }}
        >
          <CircularProgress size={32} style={{ color: "#fff" }} />
        </div>
      ) : !event || errorLoading ? (
        <p style={{ color: "red", fontSize: 12 }}>{errorLoading}</p>
      ) : (
        <Grid
          container
          justifyContent="space-evenly"
          style={{
            alignItems: "center",
            padding: 20,
            minHeight: 414,
            // background: "#fff",
          }}
        >
          <Grid
            xs={12}
            md={4}
            lg={4}
            style={{ padding: 10, textAlign: "left" }}
          >
            <Typography
              variant="h5"
              style={{ color: "#FF2E00", fontWeight: "bold" }}
            >
              {event.name}
            </Typography>
            <Typography variant="body1">
              <svg
                width="12"
                height="12"
                style={{ paddingRight: "10px" }}
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path
                  d="M11 0.923077H9V0H8V0.923077H4V0H3V0.923077H1C0.45 0.923077 0 1.33846 0 1.84615V11.0769C0 11.5846 0.45 12 1 12H11C11.55 12 12 11.5846 12 11.0769V1.84615C12 1.33846 11.55 0.923077 11 0.923077ZM11 11.0769H1V4.61538H11V11.0769ZM11 3.69231H1V1.84615H3V2.76923H4V1.84615H8V2.76923H9V1.84615H11V3.69231Z"
                  fill="#FF2E00"
                />{" "}
              </svg>
              {moment(event.date_of_event).format("DD/MM/YYYY")} -{" "}
              {event.start_at}h
            </Typography>

            <Typography variant="body1">
              <svg
                style={{ paddingRight: "10px" }}
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path
                  d="M4.71428 2.57142C4.29046 2.57142 3.87616 2.6971 3.52377 2.93256C3.17138 3.16802 2.89673 3.50269 2.73454 3.89424C2.57235 4.2858 2.52992 4.71666 2.6126 5.13233C2.69528 5.548 2.89937 5.92982 3.19905 6.2295C3.49874 6.52919 3.88056 6.73328 4.29623 6.81596C4.7119 6.89864 5.14276 6.8562 5.53431 6.69402C5.92587 6.53183 6.26054 6.25717 6.496 5.90478C6.73146 5.55239 6.85713 5.13809 6.85713 4.71428C6.85648 4.14616 6.63051 3.60149 6.22878 3.19977C5.82706 2.79805 5.2824 2.57208 4.71428 2.57142ZM4.71428 5.99999C4.45999 5.99999 4.21141 5.92458 3.99997 5.78331C3.78854 5.64203 3.62375 5.44123 3.52643 5.2063C3.42912 4.97137 3.40366 4.71285 3.45327 4.46345C3.50288 4.21404 3.62533 3.98495 3.80514 3.80514C3.98495 3.62533 4.21404 3.50288 4.46345 3.45327C4.71285 3.40366 4.97137 3.42912 5.2063 3.52643C5.44123 3.62375 5.64203 3.78854 5.78331 3.99997C5.92458 4.21141 5.99999 4.45999 5.99999 4.71428C5.99961 5.05515 5.86403 5.38196 5.62299 5.62299C5.38196 5.86403 5.05515 5.99961 4.71428 5.99999ZM4.71428 0C3.46441 0.00141789 2.26614 0.498555 1.38235 1.38235C0.498555 2.26614 0.00141789 3.46441 0 4.71428C0 6.3962 0.77732 8.17922 2.24801 9.87052C2.90983 10.6347 3.65456 11.3229 4.46849 11.9225C4.54053 11.9729 4.62634 12 4.71428 12C4.80222 12 4.88802 11.9729 4.96006 11.9225C5.77399 11.3229 6.51873 10.6347 7.18054 9.87052C8.65118 8.17922 9.42856 6.39626 9.42856 4.71428C9.42714 3.46441 8.93 2.26614 8.04621 1.38235C7.16242 0.498555 5.96415 0.00141789 4.71428 0ZM4.71428 11.0346C3.82858 10.3399 0.857141 7.78022 0.857141 4.71428C0.857141 3.6913 1.26352 2.71022 1.98687 1.98687C2.71022 1.26352 3.6913 0.857141 4.71428 0.857141C5.73725 0.857141 6.71833 1.26352 7.44169 1.98687C8.16504 2.71022 8.57141 3.6913 8.57141 4.71428C8.57141 7.78027 5.59981 10.3401 4.71428 11.0346Z"
                  fill="#FF2E00"
                />{" "}
              </svg>
              {event.place} - {event.address}
            </Typography>

            <hr style={{ color: "#313131", margin: "20px 0" }} />

            <Typography variant="subtitle1">{event.description}</Typography>
          </Grid>

          <Grid xs={12} md={4} lg={4} style={{ padding: 10 }}>
            <Grid
              container
              spacing={1}
              justify="center"
              style={{ margin: 0, width: "100%" }}
            >
              <Card
                style={{
                  width: "80%",
                  padding: 10,
                  // boxShadow: "0 2px 5px 2px rgba(0, 105, 135, .3)",
                  borderRadius: 12,
                  background:'black'
                }}
              >
                {(event.tickets || []).map((ticket) => {
                  const selectedTicket = selectedTickets.find(
                    (item) => item.ticket.id === ticket.id
                  );
                  return (
                    <Grid
                      style={{
                        border: `1px solid ${colors.tertiary}`,
                        padding: 10,
                        borderRadius: 20,
                        marginTop: 6,
                      }}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Grid container alignItems="center" justify="center">
                        <Grid xs={12} sm={6} md={6} lg={6}>
                          <h5 style={{ margin: 0 }}>{ticket.types.name}</h5>
                          <h5 style={{ margin: 0 }}>
                            {ticket.types.description}
                          </h5>
                          <h5
                            style={{
                              margin: 0,
                              marginTop: 6,
                              fontWeight: "lighter",
                              // color: colors.primary,
                            }}
                          >
                            {formatReais(ticket.price / 100)}
                          </h5>
                        </Grid>
                        <Grid
                          style={{ margin: "10px 0" }}
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                        >
                          {/* <InputField
                            className="btn-number"
                            label="Quantidade"
                            type="number"
                            value={selectedTicket ? selectedTicket.quantity : 0}
                            onChange={(e) => {
                              setSelectedTickets((pv) => [
                                ...pv.filter(
                                  (item) => item.ticket.id !== ticket.id
                                ),
                                ...(e.target.value > 0
                                  ? [{ ticket, quantity: e.target.value }]
                                  : []),
                              ]);
                            }}
                          /> */}

                          <div
                            style={{
                              borderRadius: 15,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <Button
                              disabled={!selectedTicket?.quantity}
                              classes={{ root: classes.root }}
                              // style={{
                              //   background: colors.quaternary,
                              //   maxWidth: 60,
                              //   cursor: !selectedTicket?.quantity
                              //     ? "not-allowed"
                              //     : "pointer",
                              // }}
                              onClick={(e) => {
                                setSelectedTickets((pv) => {
                                  return [
                                    ...pv.filter(
                                      (item) => item.ticket.id !== ticket.id
                                    ),
                                    ...(selectedTicket.quantity === 1
                                      ? []
                                      : [
                                          {
                                            ticket,
                                            quantity:
                                              selectedTicket?.quantity - 1,
                                          },
                                        ]),
                                  ];
                                });
                              }}
                            >
                              -
                            </Button>
                            <label>
                              {selectedTicket ? selectedTicket?.quantity : 0}{" "}
                            </label>
                            <Button
                              disabled={selectedTicket?.quantity === 10}
                              classes={{ root: classes.root }}
                              // style={{
                              //   // color: "#fff",
                              //   // background: colors.quaternary,
                              //   maxWidth: 60,
                              // }}
                              onClick={(e) => {
                                setSelectedTickets((pv) => {
                                  return [
                                    ...pv.filter(
                                      (item) => item.ticket.id !== ticket.id
                                    ),
                                    ...[
                                      {
                                        ticket,
                                        quantity: selectedTicket?.quantity
                                          ? selectedTicket?.quantity + 1
                                          : 1,
                                      },
                                    ],
                                  ];
                                });
                              }}
                            >
                              +
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Typography variant="body1">
                    TOTAL{" "}
                    {formatReais(
                      selectedTickets.reduce(
                        (pv, item) => pv + item.quantity * item.ticket.price,
                        0
                      ) / 100
                    )}
                  </Typography>

                  <Button
                    disabled={selectedTickets.length === 0}
                    style={{
                      marginTop: 10,
                      borderColor:
                        selectedTickets.length === 0 ? "#2d2d2d" : "#FF2E00",
                      background:
                        selectedTickets.length === 0
                          ? "#070606"
                          : 'black',
                    }}
                    onClick={(e) =>
                      currentUser
                        ? setBuyingModal({})
                        : (window.location.href = "/login")
                    }
                  >
                    Checkout
                  </Button>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Dialog
            open={!!buyingModal}
            maxWidth="sm"
            fullWidth
            onClose={(e) => {
              setBuyingModal(null);
              setErrorBuy(null);
            }}
          >
            <DialogTitle style={{ background: colors.background }}>
              <IconButton
                onClick={(e) => {
                  setBuyingModal(null);
                  setErrorBuy(null);
                }}
              >
                <CloseIcon style={{ color: "#fff" }} />
              </IconButton>
            </DialogTitle>
            <form className="form-checkout" onSubmit={submitBuy}>
              <DialogContent
                style={{
                  background: colors.background,
                }}
              >
                <List>
                  {selectedTickets.map((st) => (
                    <ListItem>
                      <ListItemText
                        primary={st.ticket.types.name}
                        secondary={`${st.quantity} inscrições`}
                        style={{
                          color: colors.text,
                        }}
                        secondaryTypographyProps={{
                          style: {
                            color: colors.text,
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
                <Typography
                  variant="body1"
                  style={{
                    color: colors.text,
                  }}
                >
                  Método de pagamento
                </Typography>

                <div
                  style={{
                    border: `1px solid ${colors.border}`,
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  <RadioGroup
                    aria-label="payment_way"
                    name="payment_way"
                    value={{ ...buyingModal }.payment_way}
                    onChange={(e) =>
                      setBuyingModal({ payment_way: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="credit"
                      control={<Radio style={{ color: colors.text }} />}
                      label="Cartão de crédito"
                    />
                    <FormControlLabel
                      value="pix"
                      control={<Radio style={{ color: colors.text }} />}
                      label="Pix"
                    />
                    {/* <FormControlLabel value="debit" disabled control={<Radio />} label="Cartão de débito" /> */}
                    {/* <FormControlLabel
                      value="boleto"
                      control={<Radio />}
                      label="Boleto bancário"
                    /> */}
                  </RadioGroup>
                </div>

                {/* <InputField
                  label="Forma de pagamento"
                  component="select"
                  options={[
                    // {value: 'boleto', text: "Boleto"},
                    { value: 'credit', text: "Crédito" },
                    // {value: 'debit', text: "Débito"},
                  ]}
                  value={{ ...buyingModal }.payment_way}
                  onChange={e => setBuyingModal({ payment_way: e.target.value })}
                /> */}

                {["credit", "debit"].includes(
                  { ...buyingModal }.payment_way
                ) && (
                  <div>
                    <InputField
                      label="Número do cartão"
                      mask="credit_card"
                      name="number"
                      value={
                        { ...buyingModal }.card &&
                        { ...buyingModal }.card.number
                      }
                      onChange={(e) =>
                        setBuyingModal((bm) => ({
                          ...bm,
                          card: { ...bm.card, [e.target.name]: e.target.value },
                        }))
                      }
                    />
                    <InputField
                      label="Nome do proprietário do cartão"
                      name="holder"
                      value={
                        { ...buyingModal }.card &&
                        { ...buyingModal }.card.holder
                      }
                      onChange={(e) =>
                        setBuyingModal((bm) => ({
                          ...bm,
                          card: { ...bm.card, [e.target.name]: e.target.value },
                        }))
                      }
                    />
                    <InputField
                      label="Mês e ano de vencimento"
                      mask="credit_card_expiry"
                      name="expiry"
                      value={
                        { ...buyingModal }.card &&
                        { ...buyingModal }.card.expiry
                      }
                      onChange={(e) =>
                        setBuyingModal((bm) => ({
                          ...bm,
                          card: { ...bm.card, [e.target.name]: e.target.value },
                        }))
                      }
                    />
                    <InputField
                      label="CVV"
                      name="cvv"
                      value={
                        { ...buyingModal }.card && { ...buyingModal }.card.cvv
                      }
                      onChange={(e) =>
                        setBuyingModal((bm) => ({
                          ...bm,
                          card: { ...bm.card, [e.target.name]: e.target.value },
                        }))
                      }
                      maxLength={3}
                      style={{
                        color: colors.black,
                      }}
                    />

                    <InputField
                      label="Parcelas"
                      name="installments"
                      component="select"
                      value={
                        ({ ...buyingModal }.card &&
                          { ...buyingModal }.card.installments) ??
                        1
                      }
                      options={[
                        { value: 1, text: "1" },
                        { value: 2, text: "2" },
                        { value: 3, text: "3" },
                      ]}
                      onChange={(e) => {
                        console.log("evenet ", e.target);
                        setBuyingModal((bm) => ({
                          ...bm,
                          card: {
                            ...bm.card,
                            installments: e.target.value,
                          },
                        }));
                      }}
                      style={{
                        color: "#000",
                      }}
                    />
                  </div>
                )}

                {errorBuy && (
                  <p style={{ color: "red", fontSize: 13 }}>{errorBuy}</p>
                )}
              </DialogContent>

              <DialogActions
                style={{ padding: 24, background: colors.background }}
              >
                <Button
                  // classes={{ root: classes.root, label: classes.label }}
                  onClick={() => {
                    console.log("track facebook purchase");
                    PixelFB.fbq("track", "Purchase");
                  }}
                  type="submit"
                  disabled={loadingBuy}
                  // variant="outlined"
                  color="primary"
                >
                  {loadingBuy ? (
                    <CircularProgress size={18} />
                  ) : (
                    "EFETUAR COMPRA"
                  )}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Grid>
      )}
      <Footer />
    </ContainerEvent>
  );
}

const useStyles = makeStyles({
  root: {
    height: 42,
    background: colors.background,
    width: 60,
    maxWidth: 60,
    borderRadius: 4,
    border: 0,
    color: "white",
    "&:hover": {
      background: colors.black,
    },
    "&:disabled": {
      background: "#ddd",
      cursor: "not-allowed",
    },
  },
  outline: {
    borderRadius: 3,
    border: "1px solid #ef1f26",
    color: "#ef1f26",
    height: 48,
    padding: "0 40px",
    "&:hover": {
      background: "linear-gradient(45deg, #ef1f26 30%, #C91F25 90%)",
      color: "#fff",
    },
    "&:disabled": {
      background: "#ddd",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, .3)",
    },
  },
  label: {
    textTransform: "capitalize",
  },
});
