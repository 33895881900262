import styled from "styled-components";
import colors from "../../theme/colors";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  background: ${colors.black};

  /* first child */
  > div:first-child {
    width: 70%;
  }
  /* second child */
  > div:last-child {
    width: 30%;
  }

  /* mobile */
  @media (max-width: 768px) {
    flex-direction: column;
    > div:first-child {
      display: none;
    }
    > div:last-child {
      width: 100%;
    }
  }
`;

export const LogoArena20Y = styled.img`
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  width: 200px;
  z-index: 12;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  form.form-sign-in {
    width: 100%;
    max-width: 340px;
    color: white;

    /* .input-field {
      padding-top: 10px;
    } */
  }

  .forgot-password {
    text-align: left;
    margin-top: 22px;

    a {
      text-decoration: none;
      color: ${colors.text};
      font-size: 14px;
    }
  }
  .register-account {
    text-decoration: none;
    margin-top: 16px;
    font-size: 14px;
    color: ${colors.text};

    a {
      color: ${colors.text};
    }
  }
  .auth {
    margin: 40px 0;
  }
`;
