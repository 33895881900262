import React from "react";
import { Container, Grid } from "@material-ui/core";

import { FooterContainer } from "./styles";

export default function Footer() {
  return (
    <FooterContainer>
      <Container className="container notprint">
        <Grid container>
          <Grid sm={12} md={6} lg={6} style={{ textAlign: "center" }}>
            <img
              src="/images/arena-xp-logo-branca.png"
              alt="logo_arena_conference"
              width="120"
            />
            <p className="text-justify">
              Desde o princípio, quando o Espírito pairava sobre as águas, já
              estava predestinado. Um Homem feito de carne e sangue veio à Terra
              para sentir milhões de corações baterem ao mesmo tempo. Na sua
              trajetória você passou por milhares de desafios, sonhos
              frustrados, realizados e outros entregues ao medo. Não é apenas
              sobreviver dia após dia, mas renascer para viver por um PROPÓSITO.
              Olhe para o céu e veja quem sempre esteve além do tempo. Além de
              tudo. DEUS!
            </p>
          </Grid>

          <Grid
            sm={12}
            md={6}
            lg={6}
            style={{ textAlign: "center", width: "100%" }}
          >
            <h6>Compre pelo app</h6>

            <a
              href="https://apps.apple.com/br/app/arena-conference/id1539289226"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-download-app"
                src="/images/baixar_app_apple.png"
                alt="baixar_app_apple"
                height="32"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=br.com.pipetech.arenaconference"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-download-app"
                src="/images/baixar_app_android.png"
                alt="baixar_app_apple"
                height="32"
              />
            </a>
          </Grid>
        </Grid>
        <hr />
      </Container>
      <div>
        <Grid container>
          <Grid md={12} sm={12} xs={12}>
            <p className="copyright-text notprint">
              Copyright &copy; 2022 All Rights Reserved by Sara Nossa Terra.
            </p>
          </Grid>
        </Grid>
      </div>
    </FooterContainer>
  );
}
