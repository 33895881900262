import styled from "styled-components";

export const Container = styled.div`
  @media print {
    h1 h2 h3 h4 h5 h6 {
      color: #000 !important;
    }
  }
`;

export const ContainerButtons = styled.div`
  @media print {
    button {
      display: none;
    }
  }
`;
