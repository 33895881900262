import React from "react";
import { Link } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import ClientContext from "../../contexts/ClientContext";
import AuthContext from "../../contexts/AuthContext";

import Button from "../../components/Button";
import InputField from "../../components/InputField";

import { Container, Content } from "./styles";

export default function SignUp() {
  const [loading, setLoading] = React.useState(false);

  const { signIn } = React.useContext(AuthContext);
  const { apiRequest } = React.useContext(ClientContext);

  const [registerForm, setRegisterForm] = React.useState({ member: false });
  const [loadingRegister, setLoadingRegister] = React.useState(false);
  const [errorRegister, setErrorRegister] = React.useState(null);

  const [isMember, setIsMember] = React.useState(false);
  const [statesData, setStatesData] = React.useState([{}]);
  const [regional, setRegional] = React.useState([{}]);
  const [coordination, setCoordination] = React.useState([{}]);
  const [church, setChurch] = React.useState([{}]);
  const [team, setTeam] = React.useState([{}]);

  const DF_STATE_ID = 7;

  const getStates = (e) => {
    setLoading(true);
    apiRequest("GET", "/states")
      .then((res) => {
        setStatesData(res);
        console.log("response");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  async function getRegional(state_id) {
    if (state_id != DF_STATE_ID) {
      return false;
    }
    apiRequest("GET", "/regional?filter[state_id]=" + state_id)
      .then((res) => {
        setRegional(
          res.filter((e) => e.state_id == DF_STATE_ID && !e.deleted_at)
        );
        setCoordination([{}]);
      })
      .catch((err) => {});
  }

  async function getCoordination(regional_id) {
    if (!regional_id) {
      return false;
    }
    apiRequest("GET", "/coordination?filter[regional_id]=" + regional_id)
      .then((res) => {
        setCoordination(
          res.filter((e) => e.regional_id == regional_id && !e.deleted_at)
        );
      })
      .catch((err) => {});
  }
  async function getChurch(coordination_id) {
    if (!coordination_id) {
      return false;
    }
    apiRequest("GET", "/church?filter[coordination_id]=" + coordination_id)
      .then((res) => {
        setChurch(
          res.filter(
            (e) => e.coordination_id == coordination_id && !e.deleted_at
          )
        );
      })
      .catch((err) => {});
  }
  async function getTeam(church_id) {
    if (!church_id) {
      return false;
    }
    apiRequest("GET", "/team?filter[church_id]=" + church_id)
      .then((res) => {
        setTeam(res.filter((e) => e.church_id == church_id && !e.deleted_at));
      })
      .catch((err) => {});
  }

  const doLogin = (payload) => {
    apiRequest("POST", "/login", { ...payload })
      .then((res) => {
        signIn({ ...res });
        setLoadingRegister(false);

        window.location.replace("/evento");
      })
      .catch((err) => {
        setErrorRegister("Ocorreu um erro ao logar!.\n " + err);
      });
  };
  const submitRegister = (e) => {
    e.preventDefault();

    setLoadingRegister(true);
    setErrorRegister(null);

    apiRequest("POST", "/signup", { ...registerForm })
      .then((res) => {
        doLogin({ ...registerForm });
      })
      .catch((err) => {
        setLoadingRegister(false);
        setErrorRegister("Ocorreu um erro ao registrar.\n " + err);
      });
  };

  const handleInputChange = (e) => {
    const member = e.target.checked;
    setRegisterForm((pv) => ({ ...pv, ["member"]: member }));
    setIsMember(member);
    if (member && statesData.length == 0) {
      getRegional(registerForm.state_id);
    }
  };

  React.useEffect(() => {
    getStates();
  }, []);

  return (
    <Container>
      <Content>
        <img src="/images/arena_xperience_white.png" width="180" alt="logo arena" />
        {loading ? (
          <div
            style={{
              display: "flex",
              minHeight: "100vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>
              <CircularProgress size={18} />
            </span>
          </div>
        ) : (
          <form className="form-sign-up" onSubmit={submitRegister}>
            <InputField
              name="name"
              type="text"
              label="Nome"
              value={registerForm.name}
              onChange={(e) =>
                setRegisterForm((pv) => ({
                  ...pv,
                  [e.target.name]: e.target.value,
                }))
              }
            />

            <InputField
              name="email"
              type="email"
              label="Email"
              value={registerForm.email}
              onChange={(e) =>
                setRegisterForm((pv) => ({
                  ...pv,
                  [e.target.name]: e.target.value,
                }))
              }
            />
            <InputField
              name="password"
              type="password"
              label="Senha"
              value={registerForm.password}
              onChange={(e) =>
                setRegisterForm((pv) => ({
                  ...pv,
                  [e.target.name]: e.target.value,
                }))
              }
            />
            <InputField
              name="cpf"
              type="text"
              label="CPF"
              mask="cpf"
              value={registerForm.cpf}
              onChange={(e) =>
                setRegisterForm((pv) => ({
                  ...pv,
                  [e.target.name]: e.target.value,
                }))
              }
            />
            <InputField
              name="phone"
              type="text"
              label="Telefone"
              mask="phone"
              value={registerForm.phone}
              onChange={(e) =>
                setRegisterForm((pv) => ({
                  ...pv,
                  [e.target.name]: e.target.value,
                }))
              }
            />
            {/* <InputField
              name="birthday"
              type="text"
              mask="date_ptbr"
              label="Data de Nascimento"
              value={registerForm.birthday}
              onChange={(e) =>
                setRegisterForm((pv) => ({
                  ...pv,
                  [e.target.name]: e.target.value,
                }))
              }
            /> */}
            <div style={{ textAlign: "left" }}>
              <InputField
                component="checkbox"
                label="É membro da igreja?"
                value={registerForm.member}
                checked={isMember}
                onChange={handleInputChange}
                // onChange={e => setRegisterForm(pv => ({...pv, [e.target.name]: e.target.value}))}
              />
            </div>

            {/* Estado */}
            <InputField
              name="state_id"
              label="Estado"
              component="select"
              value={registerForm.state_id}
              onChange={(e) => {
                setRegisterForm((pv) => ({
                  ...pv,
                  [e.target.name]: e.target.value,
                }));
                getRegional(e.target.value);
              }}
              options={statesData.map((e) => ({
                ...e,
                value: e.id,
                text: e.name,
              }))}
            />

            <InputField
              name="city"
              type="text"
              label="Cidade"
              value={registerForm.city}
              onChange={(e) =>
                setRegisterForm((pv) => ({
                  ...pv,
                  [e.target.name]: e.target.value,
                }))
              }
            />
            {isMember && registerForm.state_id == DF_STATE_ID && (
              <InputField
                name="regional_id"
                label="Regional"
                component="select"
                value={registerForm.regional_id}
                onChange={(e) => {
                  setRegisterForm((pv) => ({
                    ...pv,
                    [e.target.name]: e.target.value,
                  }));
                  getCoordination(e.target.value);
                }}
                options={regional.map((e) => ({
                  ...e,
                  value: e.id,
                  text: e.name,
                }))}
              />
            )}
            {isMember && registerForm.state_id == DF_STATE_ID && (
              <InputField
                name="coordination_id"
                label="Coordenação"
                component="select"
                value={registerForm.coordination_id}
                onChange={(e) => {
                  setRegisterForm((pv) => ({
                    ...pv,
                    [e.target.name]: e.target.value,
                  }));
                  getChurch(e.target.value);
                }}
                options={coordination.map((e) => ({
                  ...e,
                  value: e.id,
                  text: e.name,
                }))}
              />
            )}

            {isMember && registerForm.state_id == DF_STATE_ID && (
              <InputField
                name="church_id"
                label="Igreja"
                component="select"
                value={registerForm.church_id}
                onChange={(e) => {
                  setRegisterForm((pv) => ({
                    ...pv,
                    [e.target.name]: e.target.value,
                  }));
                  getTeam(e.target.value);
                }}
                options={church.map((e) => ({
                  ...e,
                  ["value"]: e.id,
                  ["text"]: e.name,
                }))}
              />
            )}

            {registerForm.state_id != DF_STATE_ID && (
              <InputField
                name="church_id"
                type="text"
                label="Igreja"
                value={registerForm.church_id}
                onChange={(e) =>
                  setRegisterForm((pv) => ({
                    ...pv,
                    [e.target.name]: e.target.value,
                  }))
                }
              />
            )}
            {isMember && registerForm.state_id == DF_STATE_ID && (
              <InputField
                name="team_id"
                label="Equipe"
                component="select"
                value={registerForm.team_id}
                onChange={(e) => {
                  setRegisterForm((pv) => ({
                    ...pv,
                    [e.target.name]: e.target.value,
                  }));
                }}
                options={team.map((e) => ({
                  ...e,
                  ["value"]: e.id,
                  ["text"]: e.name,
                }))}
              />
            )}
            {errorRegister && (
              <p style={{ color: "red", fontSize: 13 }}>{errorRegister}</p>
            )}

            <div className="auth">
              <Button disabled={loadingRegister}>Cadastrar</Button>
              <div className="register-account">
                Já tem uma conta? <Link to="/login">Faça o login</Link>
              </div>
            </div>
          </form>
        )}
      </Content>
    </Container>
  );
}
