import React from "react";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";

import Button from "../../components/Button";
import InputField from "../../components/InputField";
import AuthContext from "../../contexts/AuthContext";
import ClientContext from "../../contexts/ClientContext";

import { Container, Content, LogoArena20Y } from "./styles";
import colors from "../../theme/colors";
import { BorderColor } from "@material-ui/icons";

export default function SignIn() {
  const [loginForm, setLoginForm] = React.useState({});
  const [loadingLogin, setLoadingLogin] = React.useState(false);
  const [errorLogin, setErrorLogin] = React.useState(null);

  const { apiRequest } = React.useContext(ClientContext);
  const { signIn } = React.useContext(AuthContext);

  const submitLogin = (e) => {
    e.preventDefault();
    setLoadingLogin(true);
    setErrorLogin(null);

    apiRequest("POST", "/login", { ...loginForm })
      .then((res) => {
        signIn({ ...res });
        setLoadingLogin(false);

        // @TODO
        window.location.replace("/evento");
      })
      .catch((err) => {
        setLoadingLogin(false);
        setErrorLogin("Login e/ou senha inválidos");
      });
  };

  return (
    <Container>
      <Content>
        <div
          className="overlay"
          style={{
            opacity: 0.9,
            width: "100%",
            height: "100%",
          }}
        >
          <img
            src="/images/teste.png"
            style={{ width: "98%", height: "100%", objectFit:'cover' }}
            alt="Bg"
          />
          {/* <LogoArena20Y
            src="/images/arena-xp-logo-branca.png"
            alt="Logo Arena"
          /> */}
        </div>
      </Content>
      <Content>
        <Card
          style={{
            padding: 25,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: colors.black,
          }}
        >
          {/* <img src="/images/arena_xperience_white.png" width="180" alt="logo" /> */}

          <form className="form-sign-in" onSubmit={submitLogin}>
            <InputField
              name="email"
              type="email"
              label="Email"
              value={loginForm.email}
              onChange={(e) =>
                setLoginForm((pv) => ({
                  ...pv,
                  [e.target.name]: e.target.value,
                }))
              }
            />
            <InputField
              style={{ padding: 10 }}
              name="password"
              type="password"
              label="Senha"
              value={loginForm.password}
              onChange={(e) =>
                setLoginForm((pv) => ({
                  ...pv,
                  [e.target.name]: e.target.value,
                }))
              }
            />

            {errorLogin && (
              <p style={{ color: "red", fontSize: 13 }}>{errorLogin}</p>
            )}

            <div className="forgot-password">
              <Link to="/forgot-password">
                <p style={{ color: "#FF2E00", fontWeight: "bold" }}>
                  Esqueci minha senha
                </p>
              </Link>
            </div>

            <div className="auth">
              <Button style={{ borderColor: "#FF2E00" }} loading={loadingLogin}>
                Entrar
              </Button>

              <div className="register-account">
                Ainda não tem uma conta?
                <Link to="/register"> Criar nova conta </Link>
              </div>
            </div>
          </form>
        </Card>
      </Content>
    </Container>
  );
}
