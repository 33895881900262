import styled from "styled-components";

export const ContainerEvent = styled.div`
  form.form-checkout {
    width: 100%;
    // max-width: 340px;

    .input-field {
      padding-top: 10px;
    }
  }
`;
