import React from "react";
import {
  CircularProgress,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
// import moment from 'moment-timezone'

import Footer from "../../components/Footer";
import SimpleHeader from "../../components/SimpleHeader";
import AuthContext from "../../contexts/AuthContext";
import ClientContext from "../../contexts/ClientContext";

// import './Event.scss'

export default function Programming() {
  // const [event, setEvent] = React.useState(null);
  const [presentialSchedule, setPresentialSchedule] = React.useState([]);
  const [vipSchedule, setVipSchedule] = React.useState([]);
  const [loadingEvent, setLoadingEvent] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState(null);

  const { apiRequest } = React.useContext(ClientContext);
  const { currentUser } = React.useContext(AuthContext);

  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);

  // TABS
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    // console.log("EVENT ", event);
    // console.log("VALUE ", newValue);
  };

  React.useEffect(() => {
    setErrorLoading(null);
    setLoadingEvent(true);

    tabValue === 0
      ? apiRequest("GET", `/schedule/presential`)
          .then((response) => {
            setLoadingEvent(false);
            // if (!event[0]) throw new Error("Evento não encontrado");
            setPresentialSchedule(response.data);
            console.log("REPOSNSE ", response);
          })
          .catch((err) => {
            setLoadingEvent(false);
            setErrorLoading(err.message);
          })
      : apiRequest("GET", `/schedule/vip`)
          .then((response) => {
            setLoadingEvent(false);
            // if (!event[0]) throw new Error("Evento não encontrado");
            setVipSchedule(response.data);
            console.log("REPOSNSE ", response);
          })
          .catch((err) => {
            setLoadingEvent(false);
            setErrorLoading(err.message);
          });
  }, [tabValue]);

  return (
    <div>
      <SimpleHeader />
      <header style={{ background: "#fff", paddingTop: 14 }}>
        <img src="/images/no-limits.png" alt="limits" height="120" />
      </header>

      {loadingEvent ? (
        <div style={{ alignItems: "center", background: "#fff" }}>
          <CircularProgress size={26} />
        </div>
      ) : errorLoading ? (
        <p style={{ color: "red", fontSize: 12 }}>{errorLoading}</p>
      ) : (
        <Grid
          container
          justify="space-evenly"
          style={{ padding: 20, background: "#fff" }}
        >
          <Grid
            xs={12}
            md={8}
            lg={8}
            style={{ padding: 10, textAlign: "left" }}
          >
            <div className={classes.root}>
              <div className={classes.demo2}>
                <StyledTabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="styled tabs example"
                >
                  <StyledTab label="Presencial" />
                  <StyledTab label="Camarote" />
                </StyledTabs>
                <Typography className={classes.padding} />
              </div>
            </div>

            {tabValue === 0 ? (
              <Grid
                container
                spacing={1}
                justify="center"
                style={{ margin: 0, width: "100%" }}
              >
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Início
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="left">
                          Título
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="left">
                          Preletor
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {presentialSchedule &&
                        presentialSchedule.map((row) => (
                          <TableRow style={{ padding: 10 }} key={row.id}>
                            <TableCell component="th" scope="row">
                              {moment(row.start_at).utc().format("DD/MM/YYYY ")}
                            </TableCell>
                            <TableCell align="left">{row.title}</TableCell>
                            <TableCell align="left">
                              {row.speaker_name}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ) : currentUser?.user?.is_vip ? (
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="left">
                        <b>Título</b>
                      </TableCell>
                      <TableCell align="left">
                        <b>Preletor</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vipSchedule &&
                      vipSchedule.map((row) => (
                        <TableRow style={{ padding: 10 }} key={row.id}>
                          <TableCell component="th" scope="row">
                            <img
                              src={row.thumbnail_url}
                              alt="thumb"
                              style={{ maxHeight: 45 }}
                            />
                          </TableCell>
                          <TableCell align="left">{row.title}</TableCell>
                          <TableCell align="left">{row.speaker_name}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div>Você não tem acesso a esse conteudo.</div>
            )}
          </Grid>
        </Grid>
      )}
      <Footer />
    </div>
  );
}

const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      // maxWidth: 40,
      width: "100%",
      backgroundColor: "#3D8ED8",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#313131",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
      color: "#313131",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo2: {
    backgroundColor: "transparent",
  },
}));
