import React from "react";
import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import moment from "moment";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";

import Button from "../../components/Button";
import InputField from "../../components/InputField";
import Footer from "../../components/Footer";
import SimpleHeader from "../../components/SimpleHeader";
import AuthContext from "../../contexts/AuthContext";
import ClientContext from "../../contexts/ClientContext";

import colors from "../../theme/colors";
import { ContainerProfile } from "./styles";

export default function Profile() {
  const classes = useStyles();

  const { apiRequest } = React.useContext(ClientContext);
  const { currentUser } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);

  const [errorForm, setErrorForm] = React.useState(null);

  const [statesData, setStatesData] = React.useState([{}]);
  const [regional, setRegional] = React.useState([{}]);
  const [coordination, setCoordination] = React.useState([{}]);
  const [church, setChurch] = React.useState([{}]);
  const [team, setTeam] = React.useState([{}]);

  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    setLoading(true);
    getStates();
    getRegional(DF_STATE_ID);

    apiRequest("GET", "/v2/user")
      .then((res) => {
        console.log("USER ", res.data);
        setUser({
          ...res.data,
          coordination_id: res.data.coordination.id,
          regional_id: res.data.region.id,
          team_id: res.data.team.id,
          church_id: res.data.church.id,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log("err ", err);
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (user?.member === 1 && user?.region?.id) {
      getCoordination(user?.region?.id);
      getChurch(user?.coordination?.id);
      getTeam(user?.church?.id);
    }
  }, [isEditing]);

  const DF_STATE_ID = 7;

  const getStates = (e) => {
    setLoading(true);
    apiRequest("GET", "/states")
      .then((res) => {
        setStatesData(res);
        console.log("response");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  async function getRegional(state_id) {
    if (state_id != DF_STATE_ID) {
      return false;
    }
    apiRequest("GET", "/regional?filter[state_id]=" + state_id)
      .then((res) => {
        setRegional(
          res.filter((e) => e.state_id == DF_STATE_ID && !e.deleted_at)
        );
        setCoordination([{}]);
      })
      .catch((err) => {});
  }

  async function getCoordination(regional_id) {
    if (!regional_id) {
      return false;
    }
    apiRequest("GET", "/coordination?filter[regional_id]=" + regional_id)
      .then((res) => {
        setCoordination(
          res.filter((e) => e.regional_id == regional_id && !e.deleted_at)
        );
      })
      .catch((err) => {});
  }
  async function getChurch(coordination_id) {
    if (!coordination_id) {
      return false;
    }
    apiRequest("GET", "/church?filter[coordination_id]=" + coordination_id)
      .then((res) => {
        setChurch(
          res.filter(
            (e) => e.coordination_id == coordination_id && !e.deleted_at
          )
        );
      })
      .catch((err) => {});
  }
  async function getTeam(church_id) {
    if (!church_id) {
      return false;
    }
    apiRequest("GET", "/team?filter[church_id]=" + church_id)
      .then((res) => {
        setTeam(res.filter((e) => e.church_id == church_id && !e.deleted_at));
      })
      .catch((err) => {});
  }

  async function submitUpdateUser() {
    setErrorForm(null);
    let tel = user?.phone?.replace(/[^0-9]+/g, "");

    if (tel.length != 11) {
      return setErrorForm("Telefone inválido.");
    }

    if (!user?.city) {
      return setErrorForm("Cidade é obrigatório.");
    }
    if (!user?.state_id) {
      return setErrorForm("Estado é obrigatório.");
    }
    if (!user?.phone) {
      return setErrorForm("Telefone é obrigatório.");
    }
    if (!user?.regional_id) {
      return setErrorForm("Regional é obrigatório.");
    }
    if (!user?.coordination_id) {
      return setErrorForm("Coordenação é obrigatório.");
    }
    if (!user?.church_id) {
      return setErrorForm("Igreja é obrigatório.");
    }
    if (!user?.team_id) {
      return setErrorForm("Equipe é obrigatório.");
    }

    setLoading(true);

    const userUpdate = {
      regional_id: user?.regional_id,
      coordination_id: user?.coordination_id,
      church_id: user?.church_id,
      team_id: user?.team_id,
      city: user?.city,
      state_id: user?.state_id,
      phone: user?.phone,
    };

    // console.log("OBJ ", userUpdate);

    apiRequest("PUT", "/update", userUpdate)
      .then((res) => {
        console.log("RESPOSTA UPDATE ", res.data);
        setUser({
          ...res.data,
          coordination_id: res.data.coordination.id,
          regional_id: res.data.region.id,
          team_id: res.data.team.id,
          church_id: res.data.church.id,
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
        // setIsEditing(false)
        // setLoading(false)
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  React.useEffect(() => {
    if (!currentUser) window.location.href = "/evento";
  }, [currentUser]);

  return (
    <ContainerProfile>
      <div
      // style={{ background: "#F4F4F7" }}
      >
        <SimpleHeader />
        <div
          style={{
            backgroundColor: "#252222",
            padding: "10px 0",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#FF2E00", fontWeight: "bold" }}
          >
            Meus dados
          </Typography>
          {isEditing ? (
            <Button
              style={{
                maxWidth: 180,
                backgroundColor: "black",
                borderColor: "#FF2E00",
              }}
              onClick={() => setIsEditing(false)}
            >
              Voltar
            </Button>
          ) : (
            <Button
              style={{
                maxWidth: 180,
                backgroundColor: "black",
                borderColor: "#FF2E00",
              }}
              onClick={() => setIsEditing(true)}
            >
              <EditIcon style={{ marginRight: 6 }} />
              Editar dados
            </Button>
          )}
        </div>

        {loading && (
          <>
            {/* <div style={{ alignItems: 'center', background: '#fff' }}><CircularProgress size={26} /></div> */}
            <Backdrop
              className={classes.backdrop}
              open={loading}
              onClick={() => {}}
            >
              <CircularProgress color="#fff" />
            </Backdrop>
          </>
        )}

        <Grid
          container
          justifyContent="center"
          style={{ marginTop: 20, paddingBottom: 20 }}
        >
          <Card
            style={{
              margin: 10,
              maxWidth: 330,
              width: "100%",
              backgroundColor: "black",
            }}
          >
            <div
              style={{
                display: "flex",
                paddingTop: 16,
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "black",
              }}
            >
              <span
                style={{
                  fontSize: "1.1rem",
                  lineHeight: 1.334,
                  letterSpacing: "0em",
                  color: "#FF2E00",
                  fontWeight: "bold",
                }}
              >
                {user?.name}
              </span>
              <span
                style={{
                  fontSize: "0.8rem",
                  lineHeight: 1.5,
                  letterSpacing: "0.009em",
                  color: colors.text,
                }}
              >
                {user?.email}
              </span>
              <span
                style={{
                  fontSize: "0.8rem",
                  lineHeight: 1.5,
                  letterSpacing: "0.009em",
                  color: colors.text,
                }}
              >
                {user?.cpf}
              </span>
            </div>

            <CardContent
              className="card-profile"
              style={{ background: "black" }}
            >
              <List className={classes.list} style={{ background: "black" }}>
                {user?.birthday !== null && (
                  <ListItem style={{ background: "black" }}>
                    <ListItemText
                      primary="Data de nascimento"
                      secondary={moment(user?.birthday).format("DD/MM/YYYY")}
                      secondaryTypographyProps={{
                        style: {
                          background: "black",
                          color: "#FF2E00",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </ListItem>
                )}

                <Divider variant="fullWidth" component="li" />

                <ListItem style={{ background: "black" }}>
                  {!isEditing ? (
                    <ListItemText
                      primary="Telefone"
                      secondary={user?.phone}
                      secondaryTypographyProps={{
                        style: {
                          background: "black",
                          color: "#FF2E00",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  ) : (
                    <InputField
                      name="phone"
                      type="text"
                      label="Telefone"
                      mask="phone"
                      value={user?.phone}
                      style={{
                        backgroundColor: "black",
                      }}
                      onChange={(e) =>
                        setUser((pv) => ({
                          ...pv,
                          [e.target.name]: e.target.value,
                        }))
                      }
                    />
                  )}
                </ListItem>

                <Divider variant="fullWidth" component="li" />
                {(user?.neighborhood || isEditing) && (
                  <>
                    <ListItem style={{ background: "black" }}>
                      {!isEditing ? (
                        <ListItemText
                          primary="Estado"
                          secondary={user?.state?.name}
                          secondaryTypographyProps={{
                            style: {
                              background: "black",
                              color: "#FF2E00",
                              fontWeight: "bold",
                            },
                          }}
                        />
                      ) : (
                        <InputField
                          name="state_id"
                          label="Estado"
                          component="select"
                          value={user?.state_id}
                          onChange={(e) => {
                            setUser((pv) => ({
                              ...pv,
                              [e.target.name]: e.target.value,
                            }));
                            getRegional(e.target.value);
                          }}
                          options={statesData.map((e) => ({
                            ...e,
                            ["value"]: e.id,
                            ["text"]: e.name,
                          }))}
                        />
                      )}
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                  </>
                )}

                <ListItem
                  style={{
                    backgroundColor: "black",
                  }}
                >
                  {!isEditing ? (
                    <ListItemText
                      primary="Cidade"
                      secondary={user?.city}
                      secondaryTypographyProps={{
                        style: {
                          background: "black",
                          color: "#FF2E00",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  ) : (
                    <InputField
                      name="city"
                      type="text"
                      label="Cidade"
                      value={user.city}
                      onChange={(e) =>
                        setUser((pv) => ({
                          ...pv,
                          [e.target.name]: e.target.value,
                        }))
                      }
                    />
                  )}
                </ListItem>

                {!isEditing && user?.state_id === DF_STATE_ID && (
                  <>
                    <Divider variant="fullWidth" component="li" />
                    <ListItem style={{ background: "black" }}>
                      <ListItemText
                        primary="Regional"
                        secondary={user?.region?.name}
                        secondaryTypographyProps={{
                          style: {
                            background: "black",
                            color: "#FF2E00",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />

                    <ListItem style={{ background: "black" }}>
                      <ListItemText
                        primary="Coordenação"
                        secondary={user?.coordination?.name}
                        secondaryTypographyProps={{
                          style: {
                            background: "black",
                            color: "#FF2E00",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />

                    <ListItem style={{ background: "black" }}>
                      <ListItemText
                        primary="Igreja"
                        secondary={user?.church?.name}
                        secondaryTypographyProps={{
                          style: {
                            background: "black",
                            color: "#FF2E00",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />

                    <ListItem style={{ background: "black" }}>
                      <ListItemText
                        primary="Equipe"
                        secondary={user?.team?.name}
                        secondaryTypographyProps={{
                          style: {
                            background: "black",
                            color: "#FF2E00",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </ListItem>
                  </>
                )}

                {user?.member === 1 &&
                  user?.state_id == DF_STATE_ID &&
                  isEditing && (
                    <>
                      <Divider
                        variant="fullWidth"
                        component="li"
                        style={{ margin: "0px 0px 10px 0px" }}
                      />
                      <InputField
                        name="regional_id"
                        label="Regional"
                        component="select"
                        value={user?.regional_id}
                        onChange={(e) => {
                          setUser((pv) => ({
                            ...pv,
                            [e.target.name]: e.target.value,
                            coordination_id: null,
                            team_id: null,
                            church_id: null,
                          }));
                          getCoordination(e.target.value);
                        }}
                        options={regional.map((e) => ({
                          ...e,
                          ["value"]: e.id,
                          ["text"]: e.name,
                        }))}
                      />
                      <Divider
                        variant="fullWidth"
                        component="li"
                        style={{ margin: "10px 0px" }}
                      />

                      <InputField
                        name="coordination_id"
                        label="Coordenação"
                        component="select"
                        value={user?.coordination_id}
                        onChange={(e) => {
                          setUser((pv) => ({
                            ...pv,
                            [e.target.name]: e.target.value,
                            team_id: null,
                            church_id: null,
                          }));
                          getChurch(e.target.value);
                        }}
                        options={coordination.map((e) => ({
                          ...e,
                          ["value"]: e.id,
                          ["text"]: e.name,
                        }))}
                      />
                      <Divider
                        variant="fullWidth"
                        component="li"
                        style={{ margin: "10px 0px" }}
                      />

                      <InputField
                        name="church_id"
                        label="Igreja"
                        component="select"
                        value={user?.church_id}
                        onChange={(e) => {
                          setUser((pv) => ({
                            ...pv,
                            [e.target.name]: e.target.value,
                          }));
                          getTeam(e.target.value);
                        }}
                        options={church.map((e) => ({
                          ...e,
                          ["value"]: e.id,
                          ["text"]: e.name,
                        }))}
                      />
                      <Divider
                        variant="fullWidth"
                        component="li"
                        style={{ margin: "10px 0px" }}
                      />

                      <InputField
                        name="team_id"
                        label="Equipe"
                        component="select"
                        disable={true}
                        value={user?.team_id}
                        onChange={(e) => {
                          console.log("event ", e);
                          setUser((pv) => ({
                            ...pv,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        options={team.map((e) => ({
                          ...e,
                          ["value"]: e.id,
                          ["text"]: e.name,
                        }))}
                      />
                    </>
                  )}
              </List>
              {errorForm && (
                <p style={{ color: "red", fontSize: 13 }}>{errorForm}</p>
              )}
            </CardContent>

            {isEditing && (
              <div
                style={{
                  marginTop: 22,
                  marginBottom: 12,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    width: "90%",
                    margin: "auto",
                    borderColor: "#FF2E00",
                  }}
                  onClick={submitUpdateUser}
                >
                  Atualizar dados
                </Button>
              </div>
            )}
          </Card>
        </Grid>
      </div>
      <Footer />
    </ContainerProfile>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    maxWidth: 360,
    // backgroundColor: "#fff",
    padding: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    background: "#3D8ED8",
    borderRadius: 3,
    border: 0,
    width: "100%",
    color: "white",
    height: 42,
    padding: "0 40px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#3D8ED8",
    },
  },
  outlined: {
    background: "transparent",
    width: "100%",
    maxWidth: 280,
    borderRadius: 3,
    border: "1px solid #3D8ED8",
    color: "#3D8ED8",
    height: 42,
    padding: "0 40px",
    "&:hover": {
      background: "#3D8ED8",
      color: "white",
      boxShadow: "0 3px 5px 2px rgba(0, 0, 120, .3)",
    },
  },
  label: {
    textTransform: "capitalize",
  },
}));
