import styled from "styled-components";
import colors from "../../theme/colors";

export const FooterContainer = styled.footer`
  background: black;
  padding: 45px 20px 20px;
  font-size: 15px;
  line-height: 24px;
  color: #fff;

  h6 {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 2px;
  }

  hr {
    border-top-color: ${colors.border};
    opacity: 0.5;
    width: 50%;
  }
  hr.small {
    margin: 20px 0;
  }

  .img-download-app {
    cursor: pointer;
  }
  .copyright-text {
    margin: 0;
    padding-top: 18px;
    text-align: center;
  }
  @media (max-width: 991px) {
    [class^="col-"] {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 10px;
    .copyright-text {
      text-align: center;
    }
  }
  @media print {
    .notprint {
      visibility: hidden;
    }
  }
`;
