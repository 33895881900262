import styled from "styled-components";

export const Container = styled.div`
  .content-stages {
    height: 100%;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .background-stages {
    min-height: 450px;
    background: #fff;
  }
  .name-stages {
    font-family: "druk_wide_bold";
    position: relative;
    top: -79px;
    font-weight: 700;
    cursor: pointer;
    margin: 0px;
    font-size: 19px;
    color: #fff;
  }
  .img-stages {
    max-width: 230px;
    border-radius: 40px;
    cursor: pointer;
    margin: 0px 10px;
  }
`;
