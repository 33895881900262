import styled from "styled-components";
import colors from "../../theme/colors";

export const Container = styled.div`
  min-height: 100vh;
  background: ${colors.background};
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  img {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .auth {
    margin-top: 40px;
  }

  form.form-sign-up {
    width: 100%;
    max-width: 340px;

    .input-field {
      padding-top: 10px;
    }
  }

  .register-account {
    color: ${colors.text};
    margin: 16px 0;
    font-size: 14px;

    a {
      color: ${colors.text};
    }
  }
`;
