import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NewHome from "../screens/NewHome";
import Event from "../screens/Event";
import ResetPassword from "../screens/ResetPassword";
import VerifyOrder from "../screens/VerifyOrder";
import ForgotPassword from "../screens/ForgotPassword";
import SignIn from "../screens/SignIn";
import SignUp from "../screens/SignUp/index";
import Programming from "../screens/Programming";
import MyTickets from "../screens/MyTickets";
import TicketDetail from "../screens/TicketDetail";
import Stages from "../screens/Stages";
import VideosStage from "../screens/VideosStage";
import Profile from "../screens/Profile";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <NewHome />
        </Route>
        <Route exact path="/login">
          <SignIn />
        </Route>
        <Route exact path="/register">
          <SignUp />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/evento">
          <Event />
        </Route>
        <Route exact path="/evento/programacao">
          <Programming />
        </Route>
        <Route exact path="/meus-ingressos">
          <MyTickets />
        </Route>
        <Route exact path="/meu-ingresso/:orderId">
          <TicketDetail />
        </Route>
        <Route exact path="/meus-dados">
          <Profile />
        </Route>
        <Route exact path="/palcos">
          <Stages />
        </Route>
        <Route exact path="/palcos/camarote-virtual/:stageId">
          <VideosStage />
        </Route>
        <Route exact path="/eventos/:eventId">
          <Event />
        </Route>
        <Route exact path="/reset-password/:token">
          <ResetPassword />
        </Route>
        <Route path="/verify/:orderId">
          <VerifyOrder />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
