import styled from "styled-components";

export const ContainerReset = styled.div`
  .form-reset-password {
    width: 100%;
    max-width: 100%;

    .input-field {
      padding-top: 10px;
    }
  }

  .auth {
    margin-top: 16px;
  }
`;
