import React from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import moment from "moment";

import Button from "../../components/Button";
import Footer from "../../components/Footer";
import SimpleHeader from "../../components/SimpleHeader";

import AuthContext from "../../contexts/AuthContext";
import ClientContext from "../../contexts/ClientContext";

import enviroment from "../../config/enviroment";
import colors from "../../theme/colors";
import { BorderColor } from "@material-ui/icons";

// import { Container } from './styles';

export default function MyTickets() {
  const { apiRequest } = React.useContext(ClientContext);
  const { currentUser } = React.useContext(AuthContext);

  const [tickets, setTickets] = React.useState(null);
  const [loadingTickets, setLoadingTickets] = React.useState(true);

  React.useEffect(() => {
    // setErrorLoading(null)
    setLoadingTickets(true);

    apiRequest("GET", `/order`)
      .then((response) => {
        const eventTickets = response.data.filter(
          (ticket) => ticket.event_id === enviroment.event_id
        );

        setTickets(eventTickets);
        setLoadingTickets(false);
      })
      .catch((err) => {
        console.log("ERR ", err);
        setLoadingTickets(false);
        // setErrorLoading(err.message)
      });
  }, []);

  React.useEffect(() => {
    if (!currentUser) window.location.href = "/evento";
  }, [currentUser]);

  return (
    <>
      <div>
        <SimpleHeader />
        <div
          style={{
            backgroundColor: '#252222',
            padding: "10px 0",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Typography style={{ color: "#FF2E00", fontWeight: "bold" }}>
            Minhas inscrições
          </Typography>
          <input
            type="text"
            name="search"
            id="searchTickets"
            // placeholder="Pesquisar"
            style={{
              padding: 10,
              borderRadius: 6,
              border: "none",
              // border: "1px solid #fff",
              background: "transparent",
            }}
          />
        </div>

        {loadingTickets && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
              height: 100,
            }}
          >
            <CircularProgress size={26} />
          </div>
        )}

        {!loadingTickets && tickets?.length === 0 && (
          <div style={{ alignItems: "center", background: "#fff" }}>
            <img
              src="/images/sem_ingresso.png"
              alt="sem_ingresso"
              style={{ maxWidth: "65%" }}
            />
          </div>
        )}

        <Grid
          container
          justifyContent="center"
          style={{ marginTop: 20, minHeight: 400 }}
        >
          {!loadingTickets &&
            tickets?.length > 0 &&
            tickets.map((ticket) => (
              <Card
                key={ticket.id}
                style={{
                  margin: 10,
                  maxWidth: 300,
                  maxHeight: 280,
                  width: "100%",
                  backgroundColor: 'black',
                }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: 16,
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.1rem",
                      lineHeight: 1.334,
                      letterSpacing: "0em",
                      fontWeight: "normal",
                    }}
                  >
                    {ticket.ticket?.types?.name}
                  </span>
                  <span
                    style={{
                      fontSize: "0.8rem",
                      lineHeight: 1.5,
                      letterSpacing: "0.009em",
                      color: "#bbb",
                    }}
                  >
                    {"#" + ticket.id}
                  </span>
                </div>

                <hr style={{ color: "#313131" }} />

                <CardContent>
                  <div style={{ display: "flex" }}>
                    <div>
                      <svg
                        width="12"
                        height="12"
                        style={{ paddingRight: "10px" }}
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M11 0.923077H9V0H8V0.923077H4V0H3V0.923077H1C0.45 0.923077 0 1.33846 0 1.84615V11.0769C0 11.5846 0.45 12 1 12H11C11.55 12 12 11.5846 12 11.0769V1.84615C12 1.33846 11.55 0.923077 11 0.923077ZM11 11.0769H1V4.61538H11V11.0769ZM11 3.69231H1V1.84615H3V2.76923H4V1.84615H8V2.76923H9V1.84615H11V3.69231Z"
                          fill={colors.tertiary}
                        />{" "}
                      </svg>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5
                        style={{
                          color: colors.text,
                          margin: 0,
                          textAlign: "left",
                          fontWeight: "normal",
                        }}
                      >
                        {moment(ticket.event.date_of_event).format(
                          "DD/MM/YYYY"
                        )}
                      </h5>
                      <h6 style={{ margin: 0, fontWeight: "normal" }}>
                        A partir das {ticket.event.start_at}h
                      </h6>
                    </div>
                  </div>

                  <div style={{ display: "flex", marginTop: 16 }}>
                    <div>
                      <svg
                        style={{ paddingRight: "10px" }}
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          d="M4.71428 2.57142C4.29046 2.57142 3.87616 2.6971 3.52377 2.93256C3.17138 3.16802 2.89673 3.50269 2.73454 3.89424C2.57235 4.2858 2.52992 4.71666 2.6126 5.13233C2.69528 5.548 2.89937 5.92982 3.19905 6.2295C3.49874 6.52919 3.88056 6.73328 4.29623 6.81596C4.7119 6.89864 5.14276 6.8562 5.53431 6.69402C5.92587 6.53183 6.26054 6.25717 6.496 5.90478C6.73146 5.55239 6.85713 5.13809 6.85713 4.71428C6.85648 4.14616 6.63051 3.60149 6.22878 3.19977C5.82706 2.79805 5.2824 2.57208 4.71428 2.57142ZM4.71428 5.99999C4.45999 5.99999 4.21141 5.92458 3.99997 5.78331C3.78854 5.64203 3.62375 5.44123 3.52643 5.2063C3.42912 4.97137 3.40366 4.71285 3.45327 4.46345C3.50288 4.21404 3.62533 3.98495 3.80514 3.80514C3.98495 3.62533 4.21404 3.50288 4.46345 3.45327C4.71285 3.40366 4.97137 3.42912 5.2063 3.52643C5.44123 3.62375 5.64203 3.78854 5.78331 3.99997C5.92458 4.21141 5.99999 4.45999 5.99999 4.71428C5.99961 5.05515 5.86403 5.38196 5.62299 5.62299C5.38196 5.86403 5.05515 5.99961 4.71428 5.99999ZM4.71428 0C3.46441 0.00141789 2.26614 0.498555 1.38235 1.38235C0.498555 2.26614 0.00141789 3.46441 0 4.71428C0 6.3962 0.77732 8.17922 2.24801 9.87052C2.90983 10.6347 3.65456 11.3229 4.46849 11.9225C4.54053 11.9729 4.62634 12 4.71428 12C4.80222 12 4.88802 11.9729 4.96006 11.9225C5.77399 11.3229 6.51873 10.6347 7.18054 9.87052C8.65118 8.17922 9.42856 6.39626 9.42856 4.71428C9.42714 3.46441 8.93 2.26614 8.04621 1.38235C7.16242 0.498555 5.96415 0.00141789 4.71428 0ZM4.71428 11.0346C3.82858 10.3399 0.857141 7.78022 0.857141 4.71428C0.857141 3.6913 1.26352 2.71022 1.98687 1.98687C2.71022 1.26352 3.6913 0.857141 4.71428 0.857141C5.73725 0.857141 6.71833 1.26352 7.44169 1.98687C8.16504 2.71022 8.57141 3.6913 8.57141 4.71428C8.57141 7.78027 5.59981 10.3401 4.71428 11.0346Z"
                          fill={colors.tertiary}
                        />
                      </svg>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5
                        style={{
                          color: colors.text,
                          margin: 0,
                          textAlign: "left",
                          fontWeight: "normal",
                        }}
                      >
                        {ticket.event.place}
                      </h5>
                      <h6 style={{ margin: 0, fontWeight: "normal" }}>
                        {ticket.event.address}
                      </h6>
                    </div>
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <Button
                      // classes={{ root: classes.outlined, label: classes.label }}
                      // type="submit"
                      // variant="outlined"
                      type="button"
                      style={{
                        backgroundColor: 'black',
                        borderColor: "#FF2E00",
                      }}
                      // color="primary"
                      onClick={() =>
                        (window.location.href = `/meu-ingresso/${ticket.order_number}`)
                      }
                    >
                      Ver inscrição
                    </Button>
                  </div>
                </CardContent>
              </Card>
            ))}
        </Grid>
      </div>
      <Footer />
    </>
  );
}
