import { createGlobalStyle } from "styled-components";
import React from "react";
import moment from "moment";
import "moment/locale/pt-br";

import AuthContext from "./contexts/AuthContext";
import ClientContext from "./contexts/ClientContext";
import ThemeConfig from "./theme";
import { serverRequest } from "./services/RestClient";
import Routes from "./routes";

moment.locale("pt-br");

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Notable');
  body {
    font-family: 'montserrat', sans-serif;
  }
`;

function App() {
  const [currentUser, setCurrentUser] = React.useState(
    JSON.parse(localStorage.getItem("usr-sara-eventos"))
  );

  const authContext = React.useMemo(
    () => ({
      currentUser,
      signIn: (user) => {
        localStorage.setItem("usr-sara-eventos", JSON.stringify({ ...user }));
        setCurrentUser({ ...user });
      },
      signOut: () => {
        localStorage.removeItem("usr-sara-eventos");
        setCurrentUser(null);
      },
    }),
    [currentUser]
  );

  const clientContext = React.useMemo(
    () => ({
      apiRequest: (method, url, params) =>
        serverRequest({ method, url, params, user: currentUser }),
    }),
    [currentUser]
  );

  const url = window.location.pathname;
  console.log("URL ", url);
  if (currentUser && url === "/") {
    return window.location.replace("/evento");
  }

  return (
    <AuthContext.Provider value={authContext}>
      <ClientContext.Provider value={clientContext}>
        <GlobalStyles />
        <ThemeConfig>
          {/* <div className="App"> */}
          <Routes />
          {/* </div> */}
        </ThemeConfig>
      </ClientContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
