import { Button as MButton, CircularProgress } from "@material-ui/core";
import colors from "../../theme/colors";

const Button = ({ type = "submit", loading, disabled, children, ...rest }) => {
  return (
    <MButton
      type={type}
      disabled={loading || disabled}
      color="primary"
      variant="outlined"
      {...rest}
      style={{ borderRadius: "10px", ...rest?.style }}
    >
      {loading ? (
        <CircularProgress color={colors.tertiary} size={18} />
      ) : (
        children
      )}
    </MButton>
  );
};

export default Button;
