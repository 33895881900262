import React from "react";
import { useParams } from "react-router-dom";
import { Card, CircularProgress, Container, Grid } from "@material-ui/core";

import Button from "../../components/Button";
import InputField from "../../components/InputField";
import SimpleHeader from "../../components/SimpleHeader";

import ClientContext from "../../contexts/ClientContext";
import { CheckCircle } from "@material-ui/icons";

import colors from "../../theme/colors";
import { ContainerReset } from "./styles";

export default function ResetPassword() {
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState(null);

  const [loadingReset, setLoadingReset] = React.useState(null);

  const [successReset, setSuccessReset] = React.useState(null);
  const [errorReset, setErrorReset] = React.useState(null);

  const { apiRequest } = React.useContext(ClientContext);

  const { token } = useParams();
  console.log("token ", token);

  const submitResetPassword = (e) => {
    e.preventDefault();
    setLoadingReset(true);
    setErrorReset(null);

    console.log(newPassword.length);

    if (newPassword.length < 8) {
      setLoadingReset(false);
      return setErrorReset("Insira uma senha com no mínimo 8 caracteres.");
    }

    apiRequest("POST", "/password", { token, new_password: newPassword })
      .then((res) => {
        setSuccessReset(true);
        setLoadingReset(false);
      })
      .catch((err) => {
        setLoadingReset(false);
        setErrorReset("Erro ao redefinir senha!");
      });
  };

  if (successReset)
    return (
      <div>
        <SimpleHeader />

        <Container style={{ marginTop: 24, maxWidth: 360 }}>
          <Card
            style={{
              padding: 20,
              textAlign: "center",
            }}
          >
            <CheckCircle style={{ color: "#66bb6a", fontSize: 80 }} />
            <h1 style={{ textAlign: "center", fontWeight: 300, margin: 0 }}>
              Senha redefinida com sucesso!
            </h1>
            <Button
              style={{ marginTop: 20, color: colors.background }}
              // variant="outlined"
              onClick={() => {
                window.location.replace("/");
              }}
            >
              Entrar
            </Button>
          </Card>
        </Container>
      </div>
    );

  return (
    <ContainerReset>
      <SimpleHeader />

      <Container style={{ marginTop: 12 }}>
        <Grid container spacing={1} justify="center" style={{ margin: 0 }}>
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Card
              style={{
                textAlign: "left",
                padding: "20px",
                background: colors.background,
              }}
            >
              <h1 style={{ color: colors.text }}>Redefinir senha</h1>
              <form className="form-reset-password">
                <InputField
                  label="Nova senha"
                  name="novaSenha"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />

                <InputField
                  label="Confirme sua nova senha"
                  name="confirmNovaSenha"
                  type="password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />

                <div className="auth">
                  <Button
                    onClick={submitResetPassword}
                    disabled={
                      newPassword !== confirmNewPassword || loadingReset
                    }
                  >
                    {loadingReset ? (
                      <CircularProgress size={18} />
                    ) : (
                      "CONFIRMAR"
                    )}
                  </Button>
                </div>
              </form>

              {errorReset && (
                <p style={{ color: "red", fontSize: 13 }}>{errorReset}</p>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </ContainerReset>
  );
}
