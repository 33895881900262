import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";

import Footer from "../../components/Footer";
import SimpleHeader from "../../components/SimpleHeader";

import AuthContext from "../../contexts/AuthContext";
import ClientContext from "../../contexts/ClientContext";
import { Container } from "./styles";

export default function Stages() {
  const { apiRequest } = React.useContext(ClientContext);
  const { currentUser } = React.useContext(AuthContext);

  const [loadingStages, setLoadingStages] = React.useState(true);
  const [stages, setStages] = React.useState([]);
  const [search, setSearch] = React.useState("");

  if (!currentUser || !currentUser?.user?.is_vip) {
    window.location.replace("/evento");
  }
  let stagesFiltered = stages;

  if (search) {
    stagesFiltered = stagesFiltered.filter((stage) =>
      stage.name?.toLowerCase().includes(search)
    );
  }

  React.useEffect(() => {
    // setErrorLoading(null)
    setLoadingStages(true);

    apiRequest("GET", `/vip_area/stages`)
      .then((response) => {
        console.log("response ", response.data);
        setStages(response.data);
        setLoadingStages(false);
      })
      .catch((err) => {
        console.log("ERR ", err);
        setLoadingStages(false);
        // setErrorLoading(err.message)
      });
  }, []);

  return (
    <Container>
      <SimpleHeader />
      <div
        style={{
          backgroundColor: "#fff",
          padding: "5px 0",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <h4>Ao vivo</h4>
        <input
          type="text"
          name="searchStages"
          id="searchStages"
          style={{ padding: 10, borderRadius: 6, border: "1px solid #fff" }}
          onChange={(e) => setSearch(e.target?.value?.toLowerCase())}
        />
      </div>

      <div className="background-stages">
        {loadingStages && (
          <div style={{ alignItems: "center", background: "#fff" }}>
            <CircularProgress size={26} />
          </div>
        )}

        {!loadingStages && stagesFiltered.length === 0 && (
          <div
            style={{
              height: "20vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 20,
            }}
          >
            <span>Nenhum palco encontrado</span>
          </div>
        )}

        <div className="content-stages">
          <Grid container style={{ marginTop: 50, justifyContent: "center" }}>
            {!loadingStages &&
              stagesFiltered &&
              stagesFiltered.map((stage) => (
                <Grid lg={4} md={4} sm={12}>
                  <div
                  // style={{ background: "#333" }} className="img-stages"
                  >
                    <img
                      className="img-stages"
                      src="/images/bg_stages.jpeg"
                      // width={40}
                      alt="nuvem"
                      onClick={() =>
                        (window.location.href = `/palcos/camarote-virtual/${stage.id}`)
                      }
                    />

                    <p
                      className="name-stages"
                      onClick={() =>
                        (window.location.href = `/palcos/camarote-virtual/${stage.id}`)
                      }
                    >
                      {stage?.name?.toUpperCase()}
                    </p>
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      </div>

      <Footer />
    </Container>
  );
}
