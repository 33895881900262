import React from "react";
import Button from "../../components/Button";
import { Container, Content } from "./styles";

export default function NewHome() {
  return (
    <Container>
      <Content>
        <div
          style={{
            backgroundImage: `url("/images/bg-xp.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/images/arena-xp-logo-laranja.png"
            width="280"
            alt="Logo"
            style={{ marginBottom: "20px" }}
          />
          <Button
            style={{ background: "black", borderColor: "#FF2E00", marginBottom: "10px", width:'30%' }}
            onClick={() => (window.location.href = "/login")}
          >
            Login
          </Button>
          <Button
            style={{ background: "black", borderColor: "#FF2E00",  width:'30%' }}
            onClick={() => (window.location.href = "/register")}
          >
            Criar minha conta
          </Button>
        </div>
      </Content>
    </Container>
  );
}
