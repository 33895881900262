const colors = {
  primary: "#262626",
  secundary: "#FFFFFF",
  tertiary: "#FFFFFF",
  quaternary: "#FC6B87",
  quintenary: "#F7823B",

  placeholder: "#9A9A9A",

  text: "#FFFFFF",
  textSecundary: "#9B9B9B",

  border: "#E5E5E5",

  success: "#4CAF50",
  error: "#F45",

  white: "#FFFFFF",
  black: "#000000",

  background: "#121212",
  card: '#1E1E1E',
  darkLight: '#2E2E2E',

  gradient: "linear-gradient(to bottom right, #F4116E, #FC6B87)",
};

export default colors;
