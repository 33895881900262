import React from "react";
import {
  // Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import {
  CircularProgress,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import CloseIcon from "@material-ui/icons/Close";

import SimpleHeader from "../../components/SimpleHeader";
import Footer from "../../components/Footer";

import AuthContext from "../../contexts/AuthContext";
import ClientContext from "../../contexts/ClientContext";

import { formatReais } from "../../util/Util";
// import enviroment from "../../config/enviroment";
import colors from "../../theme/colors";
import Button from "../../components/Button";
import { Container, ContainerButtons } from "./styles";
import { BorderColor } from "@material-ui/icons";

export default function TicketDetail() {
  const { orderId } = useParams();

  const { apiRequest } = React.useContext(ClientContext);
  const { currentUser } = React.useContext(AuthContext);
  const [orderDetail, setOrderDetail] = React.useState(null);
  const [loadingTickets, setLoadingTickets] = React.useState(true);
  const [transferForm, setTransferForm] = React.useState({});

  const [resultEmailOptions, setResultEmailOptions] = React.useState([]);

  const [transferModal, setTransferModal] = React.useState(null);
  const [loadingTransfer, setLoadingTransfer] = React.useState(false);
  const [errorTransfer, setErrorTransfer] = React.useState(null);

  React.useEffect(() => {
    // setErrorLoading(null)
    setLoadingTickets(true);

    apiRequest("POST", `/order/detail`, { order_code: orderId })
      .then((response) => {
        console.log("TICKET ", response.data[0]);
        setOrderDetail(response.data[0]);
        // const eventTickets = response.data.filter(ticket => ticket.event_id === enviroment.event_id)

        // setTickets(eventTickets)
        setLoadingTickets(false);
      })
      .catch((err) => {
        console.log("ERR ", err);
        setLoadingTickets(false);
        // setErrorLoading(err.message)
      });
  }, []);

  React.useEffect(() => {
    if (!currentUser) window.location.href = "/evento";
  }, [currentUser]);

  const handleChange = (event) => {
    apiRequest("GET", `/users/autocomplete?q=${event}`)
      .then((response) => {
        console.log("user ", response);
        setResultEmailOptions(response);
        // const eventTickets = response.data.filter(ticket => ticket.event_id === enviroment.event_id)

        // setTickets(eventTickets)
        // setLoadingTickets(false)
      })
      .catch((err) => {
        console.log("ERR ", err);
        // setLoadingTickets(false)
        // setErrorLoading(err.message)
      });
  };

  const submitTransfer = (e) => {
    e.preventDefault();

    try {
      setLoadingTransfer(true);
      setErrorTransfer(null);

      const params = {
        ticket_ids: [String(orderDetail.id)],
      };

      if (!transferForm.email) throw new Error("Informe o e-mail.");

      apiRequest(
        "POST",
        `/tickets/transfer/${transferForm.userTransferId}`,
        params
      )
        .then(({ data }) => {
          setLoadingTransfer(false);

          setTimeout(() => {
            window.location.replace("/meus-ingressos");
          }, 2000);
        })
        .catch((err) => {
          setLoadingTransfer(false);
          err.message
            ? setErrorTransfer(err.message)
            : setErrorTransfer(
                "Erro ao transferir ingresso, tente novamente mais tarde."
              );
          console.log("EERR ", err);
        });
    } catch (err) {
      setLoadingTransfer(false);
      err.message
        ? setErrorTransfer(err.message)
        : setErrorTransfer(
            "Erro ao transferir ingresso, tente novamente mais tarde."
          );
      console.log("EERR ", err);
    }
  };

  return (
    <>
      <Container>
        <SimpleHeader />
        <Grid container justifyContent="center">
          <Card
            style={{
              margin: 10,
              maxWidth: 340,
              width: "100%",
              padding: 10,
              marginTop: 30,
              marginBottom: 30,
              backgroundColor: "black",
            }}
          >
            <img
              src="/images/arena-xp-logo-branca.png"
              alt="logo_arena_conference"
              width="120"
              style={{ marginTop: 10 }}
            />
            {/* <h3>{orderDetail?.ticket?.types.name}</h3> */}

            <hr style={{ color: "#313131" }} />

            <CardContent>
              <div style={{ textAlign: "left" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <h6 style={{ margin: 0, marginTop: 12 }}>Categoria</h6>
                    <h5
                      style={{
                        margin: 0,
                        marginTop: 4,
                        color: "#FF2E00",
                        fontWeight: "bold",
                      }}
                    >
                      {orderDetail?.ticket?.types.name}
                    </h5>
                  </div>
                  <div>
                    <h6 style={{ margin: 0, marginTop: 12 }}>Quantidade</h6>
                    <h5
                      style={{
                        margin: 0,
                        marginTop: 4,
                        color: "#FF2E00",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {orderDetail?.quantity}{" "}
                    </h5>
                  </div>
                </div>
                <div>
                  <h6 style={{ margin: 0, marginTop: 12 }}>Nome</h6>
                  <h5
                    style={{
                      margin: 0,
                      marginTop: 4,
                      color: "#FF2E00",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {orderDetail?.order?.user?.name}{" "}
                  </h5>
                </div>
                <div>
                  <h6 style={{ margin: 0, marginTop: 12 }}>Status</h6>
                  <h5
                    style={{
                      margin: 0,
                      marginTop: 4,
                      color: "#FF2E00",
                      fontWeight: "bold",
                    }}
                  >
                    {orderDetail?.delivery_status === 0
                      ? "Disponível para retirada"
                      : "Ingresso entregue"}
                  </h5>
                </div>
              </div>

              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <QRCode value={`${orderDetail?.order_number}`} size={150} />
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 16,
                  justifyContent: "space-between",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex", marginTop: 16, width: "100%" }}>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      style={{ paddingRight: "10px" }}
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {" "}
                      <path
                        d="M11 0.923077H9V0H8V0.923077H4V0H3V0.923077H1C0.45 0.923077 0 1.33846 0 1.84615V11.0769C0 11.5846 0.45 12 1 12H11C11.55 12 12 11.5846 12 11.0769V1.84615C12 1.33846 11.55 0.923077 11 0.923077ZM11 11.0769H1V4.61538H11V11.0769ZM11 3.69231H1V1.84615H3V2.76923H4V1.84615H8V2.76923H9V1.84615H11V3.69231Z"
                        fill={colors.white}
                      />{" "}
                    </svg>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5 style={{ margin: 0, textAlign: "left" }}>Data</h5>
                    <h6
                      style={{
                        margin: 0,
                        marginTop: 4,
                        fontWeight: "bold",
                        color: "#FF2E00",
                      }}
                    >
                      {moment(orderDetail?.event?.date_of_event).format(
                        "DD/MM/YYYY"
                      )}
                      {/* A partir das {orderDetail?.event?.start_at}h */}
                    </h6>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 16,
                    paddingLeft: 10,
                    width: "100%",
                  }}
                >
                  <div>
                    <svg
                      width="12"
                      height="12"
                      style={{ paddingRight: "10px" }}
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {" "}
                      <path
                        d="M11 0.923077H9V0H8V0.923077H4V0H3V0.923077H1C0.45 0.923077 0 1.33846 0 1.84615V11.0769C0 11.5846 0.45 12 1 12H11C11.55 12 12 11.5846 12 11.0769V1.84615C12 1.33846 11.55 0.923077 11 0.923077ZM11 11.0769H1V4.61538H11V11.0769ZM11 3.69231H1V1.84615H3V2.76923H4V1.84615H8V2.76923H9V1.84615H11V3.69231Z"
                        fill={colors.white}
                      />{" "}
                    </svg>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5 style={{ margin: 0, textAlign: "left" }}>Pagamento</h5>
                    <h6
                      style={{
                        margin: 0,
                        marginTop: 4,
                        fontWeight: "bold",
                        color: "#FF2E00",
                      }}
                    >
                      {orderDetail?.order?.payment_type}
                    </h6>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: 16,
                  justifyContent: "space-between",
                  textAlign: "left",
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <div>
                    <svg
                      style={{ paddingRight: "10px" }}
                      width="10"
                      height="12"
                      viewBox="0 0 10 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {" "}
                      <path
                        d="M4.71428 2.57142C4.29046 2.57142 3.87616 2.6971 3.52377 2.93256C3.17138 3.16802 2.89673 3.50269 2.73454 3.89424C2.57235 4.2858 2.52992 4.71666 2.6126 5.13233C2.69528 5.548 2.89937 5.92982 3.19905 6.2295C3.49874 6.52919 3.88056 6.73328 4.29623 6.81596C4.7119 6.89864 5.14276 6.8562 5.53431 6.69402C5.92587 6.53183 6.26054 6.25717 6.496 5.90478C6.73146 5.55239 6.85713 5.13809 6.85713 4.71428C6.85648 4.14616 6.63051 3.60149 6.22878 3.19977C5.82706 2.79805 5.2824 2.57208 4.71428 2.57142ZM4.71428 5.99999C4.45999 5.99999 4.21141 5.92458 3.99997 5.78331C3.78854 5.64203 3.62375 5.44123 3.52643 5.2063C3.42912 4.97137 3.40366 4.71285 3.45327 4.46345C3.50288 4.21404 3.62533 3.98495 3.80514 3.80514C3.98495 3.62533 4.21404 3.50288 4.46345 3.45327C4.71285 3.40366 4.97137 3.42912 5.2063 3.52643C5.44123 3.62375 5.64203 3.78854 5.78331 3.99997C5.92458 4.21141 5.99999 4.45999 5.99999 4.71428C5.99961 5.05515 5.86403 5.38196 5.62299 5.62299C5.38196 5.86403 5.05515 5.99961 4.71428 5.99999ZM4.71428 0C3.46441 0.00141789 2.26614 0.498555 1.38235 1.38235C0.498555 2.26614 0.00141789 3.46441 0 4.71428C0 6.3962 0.77732 8.17922 2.24801 9.87052C2.90983 10.6347 3.65456 11.3229 4.46849 11.9225C4.54053 11.9729 4.62634 12 4.71428 12C4.80222 12 4.88802 11.9729 4.96006 11.9225C5.77399 11.3229 6.51873 10.6347 7.18054 9.87052C8.65118 8.17922 9.42856 6.39626 9.42856 4.71428C9.42714 3.46441 8.93 2.26614 8.04621 1.38235C7.16242 0.498555 5.96415 0.00141789 4.71428 0ZM4.71428 11.0346C3.82858 10.3399 0.857141 7.78022 0.857141 4.71428C0.857141 3.6913 1.26352 2.71022 1.98687 1.98687C2.71022 1.26352 3.6913 0.857141 4.71428 0.857141C5.73725 0.857141 6.71833 1.26352 7.44169 1.98687C8.16504 2.71022 8.57141 3.6913 8.57141 4.71428C8.57141 7.78027 5.59981 10.3401 4.71428 11.0346Z"
                        fill={colors.white}
                      />{" "}
                    </svg>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5 style={{ margin: 0, textAlign: "left" }}>
                      {orderDetail?.event.place}
                    </h5>
                    <h6
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        marginTop: 4,
                        color: "#FF2E00",
                      }}
                    >
                      {orderDetail?.event.address}
                    </h6>
                  </div>
                </div>
                <div
                  style={{ display: "flex", paddingLeft: 10, width: "100%" }}
                >
                  <div>
                    <svg
                      style={{ paddingRight: "10px" }}
                      width="10"
                      height="12"
                      viewBox="0 0 10 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {" "}
                      <path
                        d="M4.71428 2.57142C4.29046 2.57142 3.87616 2.6971 3.52377 2.93256C3.17138 3.16802 2.89673 3.50269 2.73454 3.89424C2.57235 4.2858 2.52992 4.71666 2.6126 5.13233C2.69528 5.548 2.89937 5.92982 3.19905 6.2295C3.49874 6.52919 3.88056 6.73328 4.29623 6.81596C4.7119 6.89864 5.14276 6.8562 5.53431 6.69402C5.92587 6.53183 6.26054 6.25717 6.496 5.90478C6.73146 5.55239 6.85713 5.13809 6.85713 4.71428C6.85648 4.14616 6.63051 3.60149 6.22878 3.19977C5.82706 2.79805 5.2824 2.57208 4.71428 2.57142ZM4.71428 5.99999C4.45999 5.99999 4.21141 5.92458 3.99997 5.78331C3.78854 5.64203 3.62375 5.44123 3.52643 5.2063C3.42912 4.97137 3.40366 4.71285 3.45327 4.46345C3.50288 4.21404 3.62533 3.98495 3.80514 3.80514C3.98495 3.62533 4.21404 3.50288 4.46345 3.45327C4.71285 3.40366 4.97137 3.42912 5.2063 3.52643C5.44123 3.62375 5.64203 3.78854 5.78331 3.99997C5.92458 4.21141 5.99999 4.45999 5.99999 4.71428C5.99961 5.05515 5.86403 5.38196 5.62299 5.62299C5.38196 5.86403 5.05515 5.99961 4.71428 5.99999ZM4.71428 0C3.46441 0.00141789 2.26614 0.498555 1.38235 1.38235C0.498555 2.26614 0.00141789 3.46441 0 4.71428C0 6.3962 0.77732 8.17922 2.24801 9.87052C2.90983 10.6347 3.65456 11.3229 4.46849 11.9225C4.54053 11.9729 4.62634 12 4.71428 12C4.80222 12 4.88802 11.9729 4.96006 11.9225C5.77399 11.3229 6.51873 10.6347 7.18054 9.87052C8.65118 8.17922 9.42856 6.39626 9.42856 4.71428C9.42714 3.46441 8.93 2.26614 8.04621 1.38235C7.16242 0.498555 5.96415 0.00141789 4.71428 0ZM4.71428 11.0346C3.82858 10.3399 0.857141 7.78022 0.857141 4.71428C0.857141 3.6913 1.26352 2.71022 1.98687 1.98687C2.71022 1.26352 3.6913 0.857141 4.71428 0.857141C5.73725 0.857141 6.71833 1.26352 7.44169 1.98687C8.16504 2.71022 8.57141 3.6913 8.57141 4.71428C8.57141 7.78027 5.59981 10.3401 4.71428 11.0346Z"
                        fill={colors.text}
                      />{" "}
                    </svg>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5 style={{ margin: 0, textAlign: "left" }}>
                      Total da compra
                    </h5>
                    <h6
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        marginTop: 4,
                        color: "#FF2E00",
                      }}
                    >
                      {formatReais(
                        (orderDetail?.price * orderDetail?.quantity) / 100
                      )}
                    </h6>
                  </div>
                </div>
              </div>

              <ContainerButtons style={{ marginTop: 40, display: "flex" }}>
                <Button
                  style={{
                    background: colors.black,
                    borderColor: "#FF2E00",
                  }}
                  type="button"
                  onClick={() => window.print()}
                >
                  Imprimir
                </Button>
                {orderDetail?.delivery_status === 0 && (
                  <Button
                    style={{
                      border: "1px solid",
                      marginLeft: 10,
                      borderColor: "#FF2E00",
                    }}
                    onClick={() => setTransferModal(true)}
                  >
                    Transferir
                  </Button>
                )}
              </ContainerButtons>
              <div>
                <h5
                  style={{
                    margin: 0,
                    marginTop: 22,
                    color: colors.text,
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => window.history.back()}
                >
                  Voltar
                </h5>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Dialog
          open={!!transferModal}
          maxWidth="sm"
          fullWidth
          onClose={(e) => {
            setTransferModal(null);
            setErrorTransfer(null);
          }}
        >
          <DialogTitle style={{ background: colors.background }}>
            <IconButton
              onClick={(e) => {
                setTransferModal(null);
                setErrorTransfer(null);
              }}
            >
              <CloseIcon style={{ color: "#fff" }} />
            </IconButton>
          </DialogTitle>
          <form className="form-checkout" onSubmit={submitTransfer}>
            <DialogContent>
              <h4>Transferência de inscrição</h4>

              <div>
                {/* <InputField
                  name="email"
                  type="email"
                  label="Email"
                  autocomplete="off"
                  value={transferForm.email}
                  // onChange={handleChange}
                  onChange={e => {
                    handleChange(e)
                    return setTransferForm(pv => ({ ...pv, [e.target.name]: e.target.value }))
                  }}
                /> */}

                <Autocomplete
                  options={resultEmailOptions}
                  getOptionLabel={(option) => option.email}
                  // style={{ width: 300 }}
                  // inputValue={(value, newValue) => {
                  //   console.log(value, newValue)
                  // }}
                  onInputChange={(event, value) => {
                    // console.log(event, value)
                    handleChange(value);
                  }}
                  onChange={(event, value) => {
                    // console.log('ONCHANGE')
                    // console.log(value)
                    setTransferForm((pv) => ({
                      ...pv,
                      email: value?.email,
                      userTransferId: value?.id,
                    }));
                  }}
                  getOptionSelected={(option, value) =>
                    option.email === value.email
                  }
                  renderInput={
                    (params) => (
                      <TextField
                        label="E-mail"
                        {...params}
                        name="email"
                        variant="outlined"
                      />
                    )
                    // <InputField
                    //   {...params}
                    //   name="email"
                    //   type="email"
                    //   label="Email"
                    //   value={transferForm.email}
                    //   // onChange={handleChange}
                    //   onChange={e => {
                    //     handleChange(e)
                    //     console.log('onchangeeeee', e)
                    //     return setTransferForm(pv => ({ ...pv, [e.target.name]: e.target.value }))
                    //   }}
                    // />
                  }
                />
                {/* <InputField
                  name="quantity"
                  type="number"
                  label="Quantidade de ingressos"
                  value={transferForm ? transferForm.quantity : 0}
                  onChange={e => setTransferForm(pv => ({ ...pv, [e.target.name]: e.target.value }))}
                /> */}
              </div>

              {errorTransfer && (
                <p style={{ color: "red", fontSize: 13 }}>{errorTransfer}</p>
              )}
            </DialogContent>

            <DialogActions style={{ padding: 24 }}>
              <Button
                // classes={{ root: classes.root, label: classes.label }}
                onClick={() => {
                  console.log("track facebook purchase");
                }}
                type="submit"
                disabled={loadingTransfer}
                // variant="outlined"
                color="primary"
              >
                {loadingTransfer ? (
                  <CircularProgress size={18} />
                ) : (
                  "Transferir"
                )}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Container>
      <Footer />
    </>
  );
}

const useStyles = makeStyles({
  root: {
    background: "#3D8ED8",
    borderRadius: 3,
    border: 0,
    width: "100%",
    color: "white",
    height: 42,
    padding: "0 40px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#3D8ED8",
    },
  },
  outlined: {
    background: "transparent",
    width: "100%",
    maxWidth: 280,
    borderRadius: 3,
    border: "1px solid #3D8ED8",
    color: "#3D8ED8",
    height: 42,
    padding: "0 40px",
    "&:hover": {
      background: "#3D8ED8",
      color: "white",
      boxShadow: "0 3px 5px 2px rgba(0, 0, 120, .3)",
    },
  },
  label: {
    textTransform: "capitalize",
  },
});
